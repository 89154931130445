import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AgencyService } from './payment-status.service';
import { Agency, AgencyDto } from './payment-status';
import { SearchResult } from 'src/app/shared/models/search-result';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LevelTypeService } from '../level-type/level-type.service';
import { LevelType } from '../level-type/level-type';
import { ZonalService } from '../zonal/zonal.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { Dialog } from 'primeng/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/alert/notification.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PaymentService } from '../payment/payment.service';
import { DatePipe } from '@angular/common';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnInit {
  @ViewChild('dialog') dialog: Dialog;
  // @ViewChild('department', ) department: NgSelectComponent;
  agencyDialog: boolean = false;
  cols: any[];
  zonalDto: any;
  searchResult: SearchResult<Agency> = new SearchResult<Agency>();
  agency?: AgencyDto;
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: any = 10;
  page: number;
  fromDate
  toDate
  minDate = new Date()

  filterProps: any;
  agencyList
  levelType: LevelType[];
  levelTypeDto: LevelType;
  levelMasterList: any;
  levelMasterDto: any;
  zonalList: any;
  paymentStatus = ['Pending', 'Success']
  stage1Filter = {
    projectName: '',
    name: '',
    mobile: '',
    aadhaar: '',
    status: ''
  }
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public showGstError = false;
  public showIfscError = false;
  public showBMobile = false;
  //required fields property
  ngSelectInvalid = false;
  showNameError: any;
  showMobileNoError: string;
  showEmailError: string;
  showEmailUniError: string;
  gstUniError: string;
  viewOnly: boolean;
  hamHeader: string;
  subHeader: string;
  beneficiary: any = {}
  beneficary: any;
  state: any[];
  district: any[];
  projectList: any;
  beneficiaryList: any[];
  taluk: AgencyDto[];
  villages: AgencyDto[];
  items: { label: string; command: (event: any) => void; }[];
  beneficaryForm: FormGroup;
  districtList: void;
  activeIndex: number;
  singleBeneficiaryList: any;
  label: any;
  splitDistirct: any[];
  fundFilter: any
  totalCount: any;
  pageCount: number = 0;
  paymentList: any;
  pageLength: any;
  index: number;
  sortDirection: any = 'asc';
  totalAmount: any=0;
  extendOwned: any=0;
  constructor(
    private agencyService: AgencyService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public datePipe: DatePipe,
    private levelTypeService: LevelTypeService,
    private zonalService: ZonalService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    public formBuilder: FormBuilder,
    protected paymentService: PaymentService,
  ) {
  }
  ngOnInit() {
    this.hamHeader = "Payment status"
    this.subHeader = "Payment  /  Payment status"
    this.getProjects()
    this.items = [{
      label: 'Details of acquired land',
      command: (event: any) => {
        this.activeIndex = 0;
        this.messageService.add({ severity: 'info', summary: 'First Step', detail: event.item.label });
      }
    },
    {
      label: 'Account Details',
      command: (event: any) => {
        this.activeIndex = 1;
        this.messageService.add({ severity: 'info', summary: 'Seat Selection', detail: event.item.label });
      }
    },];
    this.loadPage()
  }
  ngAfterViewInit() {
    this.updateDialogFont();
  }
  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;
    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  getProjects() {
    this.agencyService.getProjects().subscribe(
      (res: any) => {
        //console.log(res, 'res');

        this.projectList = res.body.data;
        // this.state = stateList.filter(res => res.levelTypeDto.id == 1)
        // this.district = stateList.filter(res => res.levelTypeDto.id == 2)
        // //console.log(stateList, ' this.stateList');
      },
      () => {
        this.onError();
      }
    );
  }
  clear(){
    this.stage1Filter = {
      projectName: '',
      name: '',
      mobile: '',
      aadhaar: '',
      status: ''
    }
    this.fromDate=''
    this.toDate=''
    this.loadPage()
  }

  loadPage(page?: number, params?): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query
    if (params) {
      query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        project: this.stage1Filter.projectName ?? '',
        name: this.stage1Filter.name ?? '',
        mobileNumber: this.stage1Filter.mobile ?? '',
        aadharNumber: this.stage1Filter.aadhaar ?? '',
        status: (this.stage1Filter.status != '' && this.stage1Filter.status != null) ? this.stage1Filter.status : 'Success',
        fromDate : this.datePipe.transform(this.fromDate, 'YYYY-MM-dd') ?? '',
        toDate : this.datePipe.transform(this.toDate, 'YYYY-MM-dd') ?? '',
        sortBy: params,
        sortDirection: this.sortDirection
      };
      if (this.sortDirection == 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    } else {
      query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        sortBy: 'projectName',
        sortDirection: this.sortDirection,
        project: this.stage1Filter.projectName ?? '',
        name: this.stage1Filter.name ?? '',
        mobileNumber: this.stage1Filter.mobile ?? '',
        aadharNumber: this.stage1Filter.aadhaar ?? '',
        fromDate : this.datePipe.transform(this.fromDate, 'YYYY-MM-dd') ?? '',
        toDate : this.datePipe.transform(this.toDate, 'YYYY-MM-dd') ?? '',
        status: (this.stage1Filter.status != '' && this.stage1Filter.status != null) ? this.stage1Filter.status : 'Success',
      };
    }
    this.filterProps = this.filterProps ?? [];
    this.filterProps = this.filterProps ? this.filterProps : [];
    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);
    this.agencyService.filter({ ...query }).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad);
      },
      () => {

        this.onError();
      }
    );
  }
  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }

  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,

  ): void {
    this.totalAmount=0
    this.extendOwned=0
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.paymentList = data.data
      this.totalAmount=data.totalAmount
      this.extendOwned=data.totalExtent
    this.totalCount = data.totalCount;

  }

  onPageChange(event: PageEvent) {
    //console.log(event);
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    this.page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.loadPage(this.page);
  }

  protected onError(): void {
    this.spinner.hide();

  }


  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    // this.loadPage(event.page);
  }

  vendorFilter($event) {
    let filter = new filterQuery();
    filter.key = 'name';
    filter.operation = 'contains';
    filter.value = $event.term;
    let filterquery = [];
    filterquery.push(filter);
    this.zonalService.filter({ size: 10, page: 0 }, filterquery).subscribe(
      (res: HttpResponse<any>) => {
        this.zonalList = res.body;
      },
      () => { }
    );
  }
  // Only Integer Numbers
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    var charLength = event.target.value.length
    //console.log(charLength);

    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      if (charLength == 10) {
        event.preventDefault();
        return true;
      }
    }
  }
  validateIfsc() {
    if (this.beneficiary.ifscCode) {
      this.showIfscError = !this.validationService.isIfscValid(
        this.beneficiary.ifscCode
      );
      return !this.showIfscError;
    } else {
      this.showIfscError = false;
      return true;
    }
  }

  validateBmobile() {
    if (this.beneficiary.branchMobNo) {
      this.showBMobile = !this.validationService.isMobileValid(
        this.beneficiary.branchMobNo
      );
      return !this.showBMobile;
    } else {
      this.showBMobile = false;
      return true;
    }
  }

  keyPressNumberForMobile(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) || event.target.value.length >= 10) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  keyPressNumberForAadhaar(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) || event.target.value.length >= 12) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  validateBemail() {
    if (this.beneficiary.branchEmail) {
      this.emailBranchError = !this.validationService.isEmailValid(
        this.beneficiary.branchEmail
      );
      return !this.emailBranchError;
    } else {
      this.emailBranchError = false;
      return true;
    }
  }

  saveAgency() {
    let beneficaryData = this.beneficaryForm.value
    // const isFormValid =
    // this.validateMobile() &&
    // // this.validateEmail() &&
    // this.validateIfsc()
    // this.validateBmobile() &&
    // this.validateBemail()
    // this.agency.departmentDto &&
    // this.agency.schemeDto &&
    // this.agency.name &&
    // this.agency.email &&
    // this.agency.gstNo &&
    // this.levelMasterDto;
    // if (!isFormValid) {
    //   this.ngSelectInvalid = true;
    //   return;
    // }
    // if (
    //   this.showNameError ||
    //   this.showMobileNoError ||
    //   this.showEmailUniError ||
    //   this.gstUniError
    // ) {
    //   return;
    // }

    let landFrom = []
    beneficaryData.AccountDetailsForm.forEach(element => {
      let data = {
        "benificiaryLandOwnerName": element.landOwneName,
        "aadharNumber": element.aadhaarNo,
        "mobileNumber": element.mobileNumber,
        "nameOfAccountHolder": element.accountHolderName,
        "nameOfBank": element.bankName,
        "accountNumber": element.bankAccountNo,
        "branchName": element.branchName,
        "ifscCode": element.ifscCode,
        "compensationAmount": element.amount,
      }
      landFrom.push(data)
    });

    let Payload = {
      "projectID": beneficaryData.projectName.id,
      "districtLGDCode": beneficaryData.disTrictName.id,
      "talukLGDCode": beneficaryData.taluk.id,
      "revenueLGDCode": beneficaryData.village.id,
      "surveyNumber": beneficaryData.surveyNo,
      "totalCost": beneficaryData.totalCost,
      "extendToBeAcquired": beneficaryData.landAcquire,
      "benificiardLandOwnerDto": landFrom
    }

    this.ngSelectInvalid = false;
    this.agency.leveltype = this.levelTypeDto?.id;
    this.agency.levelMaster = this.levelMasterDto?.id;
    this.spinner.show()
    this.agencyService.save(Payload).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.agencyDialog = false;
        this.spinner.hide()
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      () => {
        this.onError();
        this.notificationService.alertError('Error!', '');
      }
    );
  }

  async checkName() {
    const filterData = await this.getfilterdata('name', this.agency.name);
    if (filterData.length !== 0) {
      this.showNameError = 'Name Already Exists';
    } else {
      this.showNameError = null;
    }
  }

  async checkmobile() {
    const filterData = await this.getfilterdata('mobile', this.agency.mobile);
    if (filterData.length !== 0) {
      this.showMobileNoError = 'Phone Already Exists';
    } else {
      this.showMobileNoError = null;
    }
  }

  async checkemail() {
    const filterData = await this.getfilterdata('email', this.agency.email);
    if (filterData.length !== 0) {
      this.showEmailUniError = 'Email Already Exists';
    } else {
      this.showEmailUniError = null;
    }
  }

  async checkgstNo() {
    const filterData = await this.getfilterdata('gstNo', this.agency.gstNo);
    if (filterData.length !== 0) {
      this.gstUniError = 'GST Already Exists';
    } else {
      this.gstUniError = null;
    }
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
        {
          key: 'activeFlag',
          operation: 'equals',
          value: 'YES',
        },
      ];
      if (this.agency.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.agency.id,
        });
      }
      this.agencyService.validFilter({ ...filterQuery }, filter).subscribe(
        (res: HttpResponse<Agency[]>) => {
          resolve(res.body);
        },
        () => {
          this.onError();
          resolve(null);
        }
      );
    });
  }

  downdloadPdf(data) {
    this.paymentService.downloadPdf(data.voucherNumber).subscribe(res => {
      const blob = new Blob([res.body], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'file.pdf';
      document.body.appendChild(link);
      window.open(url, '_blank');
      link.click();
      URL.revokeObjectURL(url);
      // this.spinner.hide();
      // this.router.navigate(['payment']);

      // this.notificationService.alertSuccess('Saved Successfully', '');
    },
      (err) => {
        // this.spinner.hide();
        this.notificationService.alertError('Error!', '');
      }
    )

  }

}
