import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class DashboardService {
  protected getRequestingDepartment = environment.SERVER_API_URL + 'api/dashboard/getRequestingDepartments';
  protected getExtendLand = environment.SERVER_API_URL + 'api/dashboard/getAcquiredLandByProjects';
  protected getMajorSpentUrl = environment.SERVER_API_URL + 'api/dashboard/getValuesAndPreviousMonthDatas';
  protected getProjectFUnd = environment.SERVER_API_URL + 'api/dashboard/getRequitioningBodyAndFundDetails';
  protected getBeneficiaryDistrict = environment.SERVER_API_URL + 'api/dashboard/getBeneficiariesBasedOnDistrict';
  protected getDepartment = environment.SERVER_API_URL + 'api/dashboard/getRequitioningBodyDetails';
  protected getAmountSpents = environment.SERVER_API_URL + 'api/dashboard/getBeneficiaryCountForDistricts';
  protected getAmountBalance = environment.SERVER_API_URL + 'api/dashboard/getTotalBalanceAmount';
  protected getProjectValues = environment.SERVER_API_URL + 'api/dashboard/getTotalProjectValue';
  protected getProjectPercentage = environment.SERVER_API_URL + 'api/dashboard/getDashboardRequestingBodyPercentage';
  protected getDistrict = environment.SERVER_API_URL + 'api/dashboard/getDistrictDetails';
  protected getProjects = environment.SERVER_API_URL + 'api/dashboard/getDistrictByProjects';
  protected getCompFundByLevelUrl = environment.SERVER_API_URL + 'api/getCompFundByLevel';
  protected getAmountReceiveValue = environment.SERVER_API_URL + 'api/dashboard/getTotalAmountReceived';
  protected getProjectList = environment.SERVER_API_URL + 'api/dashboard/getMonthWiseProjects';
  protected getPanchayatFundReportUrl = environment.SERVER_API_URL + 'api/getFundDetailsByLevelMaster';
  protected getMajorCompSpentUrl = environment.SERVER_API_URL + 'api/getMajorCompSpentByLevel';
  protected districtCounts = environment.SERVER_API_URL + 'api/dashboard/getExpenditureMadeOnMonthWise';
  protected projectCount= environment.SERVER_API_URL + 'api/dashboard/getDisbursementDetails';
  protected expentidure= environment.SERVER_API_URL + 'api/dashboard/getAmountDisbursedDetails';

  protected projectMonthAPI= environment.SERVER_API_URL + 'api/dashboard/getExpenditureMadeOnMonthWise';
  protected NoProject= environment.SERVER_API_URL + 'api/dashboard/getNoOfProjectDetails';
  protected fundReceived= environment.SERVER_API_URL + 'api/dashboard/getFundReceivedDetails';
  protected agencyAbstarct= environment.SERVER_API_URL + 'api/dashboard/getRequestioningBodyWiseAbstract';
  protected districtAbstarct= environment.SERVER_API_URL + 'api/dashboard/getDistrictWiseAbstarct';
  protected balance= environment.SERVER_API_URL + 'api/dashboard/getBalanceFundDetails';
  protected overSNA= environment.SERVER_API_URL + 'api/dashboard/getOverallAbstarctOfSNA';


  protected fundShortageURL= environment.SERVER_API_URL + 'api/dashboard/getFundShortageProjects';

  protected getOverall= environment.SERVER_API_URL + 'api/dashboard/report/getFilters';

  filterProps: any;
  constructor(protected http: HttpClient) { }
  getFundReport(req) {
    const options = createRequestOption(req);
    return this.http.get<any>(
      `${this.getMajorSpentUrl}`, {params:options, observe: 'response' });
  }
  getProjectFund(req) {
    const options = createRequestOption(req);

    return this.http.get<any>(
      `${this.getProjectFUnd}`, {params:options, observe: 'response' });
  }
  districtCount(req) {
    const options = createRequestOption(req);

    return this.http.get<any>(
      `${this.districtCounts}`, { params:options,observe: 'response' });
  }
  projectByCount(date) {
    const options = createRequestOption(date);

    return this.http.get<any>(
      `${this.projectCount}`, { params:date, observe: 'response' });
  }
  getList() {
    return this.http.get<any>(
      `${this.getOverall}`, { observe: 'response' });
  }
  expenditure() {
    return this.http.get<any>(
      `${this.expentidure}`, { observe: 'response' });
  }
  projectMonth(month) {
    return this.http.get<any>(
      `${this.projectMonthAPI}?month=${month}`, { observe: 'response' });
  }
  getNoOfProjects(month) {
    return this.http.get<any>(
      `${this.projectMonthAPI}?month=${month}`, { observe: 'response' });
  }
  getDepartments() {
    return this.http.get<any>(
      `${this.getDepartment}`, { observe: 'response' });
  }
  getNoProjects() {
    return this.http.get<any>(
      `${this.NoProject}`, { observe: 'response' });
  }
  getFundReceived() {
    return this.http.get<any>(
      `${this.fundReceived}`, { observe: 'response' });
  }
  getAgencyAbstract(payload) {
    return this.http.post<any>(
      `${this.agencyAbstarct}`, payload,{ observe: 'response' });
  }
  getDistrcitAbstract(payload) {
    return this.http.post<any>(
      `${this.districtAbstarct}`, payload,{ observe: 'response' });
  }
  getBalance() {
    return this.http.get<any>(
      `${this.balance}`, { observe: 'response' });
  }
  getOverSNA() {
    return this.http.get<any>(
      `${this.overSNA}`, { observe: 'response' });
  }
  getSpentAmout(req) {
    const options = createRequestOption(req);
    return this.http.get<any>(
      `${this.getAmountSpents}`, {params:options, observe: 'response' });
  }
  fundShortage(date) {
    const options = createRequestOption(date);

    return this.http.get<any>(
      `${this.fundShortageURL}`, { params:date, observe: 'response' });
  }
  getAmountBalances() {
    return this.http.get<any>(
      `${this.getAmountBalance}`, { observe: 'response' });
  }
  getBeneficiaryPercentage(req) {
    const options = createRequestOption(req);
    return this.http.get<any>(
      `${this.getProjectPercentage}`, {params:options, observe: 'response' });
  }
  getAmountReceive() {
    return this.http.get<any>(
      `${this.getAmountReceiveValue}`, { observe: 'response' });
  }
  getProjectsss() {
    return this.http.get<any>(
      `${this.getProjectList}`, { observe: 'response' });
  }
  getRequestingDepartmet() {
    return this.http.get<any>(
      `${this.getRequestingDepartment}`, { observe: 'response' });
  }
  getExtendLands() {
    return this.http.get<any>(
      `${this.getExtendLand}`, { observe: 'response' });
  }
  getRequestingDepartments() {
    return this.http.get<any>(
      `${this.getMajorSpentUrl}`, { observe: 'response' });
  }


  getDistricts(req) {
    const options = createRequestOption(req);

    return this.http.get<any>(
      `${this.getBeneficiaryDistrict}`, { params:options,observe: 'response' });
  }
  getProjectsByDistrict(id) {
    return this.http.get<any>(
      `${this.getProjects}?districtId=${id}`, { observe: 'response' });
  }

  getFunddetails(levelMasterId: number) {
    return this.http.get<any>(
      `${this.getPanchayatFundReportUrl}?levelMasterId=${levelMasterId}`,
      { observe: 'response' }
    );
  }
  getCompFundByLevel(levelId, parentId, compTypeId) {
    return this.http.get<any>(
      `${this.getCompFundByLevelUrl}?levelId=${levelId}&parentId=${parentId}&compTypeId=${compTypeId}`,
      { observe: 'response' }
    );
  }


}