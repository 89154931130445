import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './auth/signin/signin.component';
import { AgencyComponent } from './module/agency/agency.component';
import { LevelTypeComponent } from './module/level-type/level-type.component';
import { PersonalVendorsComponent } from './module/personal-vendors/personal-vendors.component';
import { ProfileComponent } from './module/profile/profile.component';
import { CheckerProjectComponent } from './module/checker-project/checker-project.component';
import { UserRouteAccessService } from './auth/user-route-access.service';
import { ErrorComponent } from './shared/error/error.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ProjectComponent } from './project/project.component';
import { PaymentComponent } from './module/payment/payment.component';
import { PaymentGenerationComponent } from './module/payment-generation/payment-generation.component';
import { PaymentStatusComponent } from './module/payment-status/payment-status.component';
import { AddProjectComponent } from './module/add-project/add-project.component';
import { AddAgencyComponent } from './module/add-agency/add-agency.component';
import { Stage1Component } from './module/stage1/stage1.component';
import { Stage2Component } from './module/stage2/stage2.component';
import { Stage3Component } from './module/stage3/stage3.component';
import { Stage4Component } from './module/stage4/stage4.component';
import { Stage5Component } from './module/stage5/stage5.component';
import { ListFundAllocationComponent } from './module/lao-list/lao-list.component';
import { AddFundAllocationComponent } from './module/add-lao/add-lao.component';
import { AgencyBasedReportComponent } from './module/agency-based-report/agency-based-report.component';
import { ProjectBasedReportComponent } from './module/project-based-report/project-based-report.component';
import { BeneficiaryBasedReportComponent } from './module/beneficiary-based-report/beneficiary-based-report.component';
import { ConsolidatedReportComponent } from './module/consolidated-report/consolidated-report.component';
import { Stage1ViewComponent } from './module/stage1-view/stage1-view.component';
import { Stage2ViewComponent } from './module/stage2-view/stage2-view.component';
import { Stage3ViewComponent } from './module/stage3-view/stage3-view.component';
import { Stage4ViewComponent } from './module/stage4-view/stage4-view.component';
import { AddProfileComponent } from './module/add-profile/add-profile.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { RequistioningDepartmentReportComponent } from './module/requistioning-department-report/requistioning-department-report.component';
import { LaoAmountReportComponent } from './module/lao-amount-report/lao-amount-report.component';
import { RaiseRequestComponent } from './module/raise-request/raise-request.component';
import { StatusRequestComponent } from './module/status-request/status-request.component';
import { PendingRequestComponent } from './module/pending-request/pending-request.component';
import { ProceedingNoComponent } from './module/proceeding-no/proceeding-no.component';
import { AccountSnaComponent } from './module/account-sna/account-sna.component';
import { BiometricTestComponent } from './module/biometric-test/biometric-test-device.component';
import { DynamicReportTableComponent } from './dynamic-report-table/dynamic-report-table.component';
import { GenericErrorComponent } from './shared/error/generic-error/generic-error.component';
import { ChangePasswordComponent } from './module/change-password/change-password.component';
import { AccountStatementComponent } from './module/account-statement/account-statement.component';
import { Stage3DDComponent } from './module/stage3-dd/stage3-dd.component';
import { RecommandDDComponent } from './module/recommand-dd/recommand-dd.component';
import { DashboardNewComponent } from './new-dashBoard/dashboard/dashboard.component';
import { DashboardOldComponent } from './old-dashboard/dashboard/dashboard.component';
import { CourtPaymentComponent } from './module/court-payment/court-payment.component';
import { CourtPaymentStatusComponent } from './module/court-payment-status/court-payment-status.component';
import { CourtDdListComponent } from './module/court-dd-list/court-dd-list.component';
import { DdAccountStatementComponent } from './module/dd-account-statement/dd-account-statement.component';
const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: "login",
          component: SigninComponent
        },
        // {
        //   path: "biometric",
        //   component: BiometricComponent
        // },  
        {
          path: "device",
          component: BiometricTestComponent
        },

        {
          path: "",
          redirectTo: "login",
          pathMatch: "full"
        },
        {
          path: "ForgotPassword",
          component: ForgotPasswordComponent
        },
        {
          path: "home",
          component: DashboardOldComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "main-dashboard",
          component: DashboardNewComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "account-statement",
          component: AccountStatementComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "dd-account-statement",
          component: DdAccountStatementComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "account-sna",
          component: AccountSnaComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "agency-report",
          component: AgencyBasedReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "project-report",
          component: ProjectBasedReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "department-report",
          component: RequistioningDepartmentReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "lao-report",
          component: LaoAmountReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "beneficiary-report",
          component: BeneficiaryBasedReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "raise-request",
          component: RaiseRequestComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "pending-request",
          component: PendingRequestComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "dynamic-report",
          component: DynamicReportTableComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "status-request",
          component: StatusRequestComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "consolidated-report",
          component: ConsolidatedReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "lao-list",
          component: ListFundAllocationComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "add-lao",
          component: AddFundAllocationComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "profile",
          component: ProfileComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "approve-profile",
          component: ProfileComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: 'ResetPassword',
          component: ResetPasswordComponent,
          canActivate: [UserRouteAccessService]

        },
        {
          path: 'proceeding-no',
          component: ProceedingNoComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "add-profile",
          component: AddProfileComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "stage1",
          component: Stage1Component,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "stage2",
          component: Stage2Component,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "stage3",
          component: Stage3Component,
          canActivate: [UserRouteAccessService]
        },

        {
          path: "stage3-DD",
          component: Stage3DDComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "stage1-view",
          component: Stage1ViewComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "stage2-view",
          component: Stage2ViewComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "stage4-view",
          component: Stage4ViewComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "recommend-dd",
          component: RecommandDDComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "stage3-view",
          component: Stage3ViewComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "stage1-view",
          component: Stage1ViewComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "stage4",
          component: Stage4Component,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "stage5",
          component: Stage5Component,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "checker-project",
          component: CheckerProjectComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "agency",
          component: AgencyComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "payment",
          component: PaymentComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "payment-DD",
          component: CourtPaymentComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "court-DD-list",
          component: CourtDdListComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "payment-generation",
          component: PaymentGenerationComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "payment-status",
          component: PaymentStatusComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "court-dd-payment-status",
          component: CourtPaymentStatusComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "personal-vendors",
          component: PersonalVendorsComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "Add-agency",
          component: AddAgencyComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "project",
          component: ProjectComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "create-project",
          component: AddProjectComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "edit-project",
          component: ProjectComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "level-type",
          component: LevelTypeComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "change-password",
          component: ChangePasswordComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "generic-error",
          component: GenericErrorComponent
        },
        {
          path: "error",
          component: ErrorComponent
        },
        { path: '**', redirectTo: '/error', pathMatch: 'full' },

      ],
      { enableTracing: false }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }