import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { SearchResult } from '../shared/models/search-result';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportService } from './dynamic-report-service';
import { LevelMasterDto } from '../module/level-master/level-master';
import { LevelMasterService } from '../module/level-master/level-master.service';
import { FinYear } from '../module/limit-translist/limit-translist.module';
import { LimitTransListService } from '../module/limit-translist/limit-translist.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { SchemeListService } from '../module/scheme-list/scheme-list.service';
import { NavbarService } from '../layout/navbar/navbar.service';
import { EncryptService } from 'src/app/shared/encrypt.service';

@Component({
  selector: 'app-dynamic-report-table',
  templateUrl: './dynamic-report-table.component.html',
  styleUrls: ['./dynamic-report-table.component.scss'],
})
export class DynamicReportTableComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private pageDetailSubscription: Subscription;
  schemeDto: any;
  MonthOfReport: any;

  searchResult: SearchResult<any> = new SearchResult<any>();
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  currentElement: any = {};
  cols: any;
  districtLevelList: LevelMasterDto[];
  district: any;
  BlockLevelDto: null;
  blockLevelList: LevelMasterDto[];
  finYear: FinYear[];
  finYearDto: any;
  toDate: any = '';
  fromDate: any = '';
  minDate = new Date('01/03/2015');
  maxDate = new Date();
  /* Parameters for Role based CRUD operations */
  pageCRUDdata = null;
  selectKey: any;
  agencyId: any;
  keyValue: string;
  keyName: string = '';
  keyName1: string;
  keyValue1: any;
  isBack: boolean = false
  drillDown: any = false;
  selectTab: void;
  filterQuery: any;
  firstPage: number;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService,
    private reportService: ReportService,
    private levelMasterService: LevelMasterService,
    private limitTransListService: LimitTransListService,
    private datePipe: DatePipe,
    private navbarService: NavbarService,
    private encryptService: EncryptService
  ) {
  }
  ngOnInit(): void {
    this.pageDetailSubscription = this.navbarService.myData$.subscribe((data) => {
      this.selectTab = data
      this.fromDate = ''
      this.toDate = ''
      this.firstPage=0
      this.loadData(data)
    })
  }
  loadData(data?, params?) {
    // let temp =  JSON.parse(this.encryptService.decryptData(localStorage.getItem('menuDetails')))
    this.selectKey = data
    // if(data){
    //   if(data.id != this.loc StorageService.retrieve('id')){
    //     console.log('different');        
    //     this.localStorageService.store('id',data.id)
    //   }
    // }
    // console.log(this.localStorageService.retrieve('id'));
    // let pageName='';
    // if(this.localStorageService.retrieve('id') == 1){
    //   pageName = 'DISTRICT_ONBOARD'
    // }else if(this.localStorageService.retrieve('id') == 2){
    //   pageName = 'BLOCK_ONBOARD'
    // }else if(this.localStorageService.retrieve('id') == 3){
    //   pageName = 'LOGIN_USER'
    // }else if(this.localStorageService.retrieve('id') == 10){
    //   pageName = 'PAYMENT_SUCCESS'
    // }else if(this.localStorageService.retrieve('id') == 12){
    //   pageName = 'PAYMENT_FAILURE'
    // }else if(this.localStorageService.retrieve('id') == 13){
    //   pageName = 'PAYMENT_REJECT'
    // }else if(this.localStorageService.retrieve('id') == 14){
    //   pageName = 'VOUCHER_SIGN_ONE_PENDING'
    // }else if(this.localStorageService.retrieve('id') == 15){
    //   pageName = 'VOUCHER_SIGN_TWO_PENDING'
    // }else if(this.localStorageService.retrieve('id') == 16){
    //   pageName = 'VOUCHER_SIGN_THREE_PENDING'
    // }
    // temp.forEach(menuData=>{
    //   if(menuData.menuTab == pageName){
    //     this.pageCRUDdata=menuData
    //   }
    //   if(menuData.subMenus){
    //     menuData.subMenus.forEach(subMenuData=>{
    //       if(subMenuData.subMenuTab == pageName){
    //         this.pageCRUDdata=subMenuData
    //       }
    //     })        
    //   }      
    // })    
    // console.log(this.pageCRUDdata);

    this.reportService.loadReport(data).subscribe((res) => {
      if (params == undefined) {
        this.isBack = false
        this.keyName = '',
          this.keyName1 = ''
        this.drillDown = false
      } else {
        this.isBack = true
      }
      this.currentElement = res.body;
      this.cols = this.currentElement.tableHead;
      if (this.currentElement.enable_District) {
        this.levelMasterService.getLevelMaster(2).subscribe(
          (res: HttpResponse<any>) => {
            this.district = this.currentElement;
          },
          () => { }
        );
        this.onStateLevelChange();
      }

      if (this.currentElement.enable_finyear) {
        this.limitTransListService.getFinYear().subscribe(
          (res: HttpResponse<FinYear[]>) => {
            this.finYear = this.currentElement;
          },
          () => { }
        );
      }

      this.loadPage(0);
    })

    /* this.httpClient
    .get('/assets/json/report.json')
    .subscribe((reportData: any) => {
      reportData.forEach((element) => {
        if (element.menu_id === this.localStorageService.retrieve('id')) {
          console.log(element);
          
          this.currentElement = element;
          this.cols = element.tableHead;
          if (element.enable_District) {
            this.levelMasterService.getLevelMaster(2).subscribe(
              (res: HttpResponse<any>) => {
                this.district = res.body;
              },
              () => {}
            );
            this.onStateLevelChange();
          }
        
          if(element.enable_finyear){
            this.limitTransListService.getFinYear().subscribe(
              (res: HttpResponse<FinYear[]>) => {
                this.finYear = res.body;
              },
              () => {}
            );
          }
          this.loadPage(1);
        }
      });
    }); */
  }
  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    }
  }
  onDistrictLevelChange($event?) {
    this.BlockLevelDto = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.district.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 1000,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.district.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    }
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? 0;
    this.page=page
    this.filterQuery = this.currentElement?.queryparms;
    if (this.district) {
      this.filterQuery = {};
      this.filterQuery[this.currentElement?.district_field] = this.district.id
    }
    if (this.currentElement?.enable_date && !this.fromDate) {
      if (this.selectKey != 'ProjectWiseReport') {
        this.filterQuery['fromDate'] = this.datePipe.transform(new Date("01-01-2023"), 'yyyy-MM-dd');
        this.filterQuery['toDate'] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      }
    }
    if (this.currentElement?.enable_date && this.fromDate != '' && this.toDate != '') {
      this.filterQuery = {};
      this.filterQuery = this.currentElement?.queryparms;
      this.filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd');
      this.filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd');
    }
    this.filterProps = this.currentElement?.payload;
    let tempPayload1
    let tempPayload2
    let tempPayload3
    if (this.selectKey == 'ProjectWiseReport' || this.selectKey == 'ExtentWiseReport') {
      this.keyName = '',
        this.keyName1 = ''
    }
    let rodeCode = localStorage.getItem('roleCode')
    let levelMasterId = Number(localStorage.getItem('levelMasterId'))
    if (rodeCode == 'SA') {
      if (this.keyName != '' && this.keyName1 != '') {
        tempPayload1 = {
          "fromDate": this.filterQuery ? this.filterQuery['fromDate'] : null,
          "toDate": this.filterQuery ? this.filterQuery['toDate'] : null,
          "pageNo":  this.page ?? 0,
          "pageSize": this.itemsPerPage,
          "key": this.selectKey,
          [this.keyName]: this.keyValue,
          [this.keyName1]: this.keyValue1
        }
        this.filterProps = tempPayload1
      } else if (this.keyName != '') {
        tempPayload2 = {
          "fromDate": this.filterQuery ? this.filterQuery['fromDate'] : null,
          "toDate": this.filterQuery ? this.filterQuery['toDate'] : null,
          "pageNo":  this.page ?? 0,
          "pageSize": this.itemsPerPage,
          "key": this.selectKey,
          [this.keyName]: this.keyValue
        }
        this.filterProps = tempPayload2
      } else {
        tempPayload3 = {
          "fromDate": this.filterQuery ? this.filterQuery['fromDate'] : null,
          "toDate": this.filterQuery ? this.filterQuery['toDate'] : null,
          "pageNo":  this.page ?? 0,
          "pageSize": this.itemsPerPage,
          "key": this.selectKey,
        }
        this.filterProps = tempPayload3
      }
    } else {
      if (this.keyName != '' && this.keyName1 != '') {
        tempPayload1 = {
          "fromDate": this.filterQuery ? this.filterQuery['fromDate'] : null,
          "toDate": this.filterQuery ? this.filterQuery['toDate'] : null,
          "pageNo": this.page ?? 0,
          "pageSize": this.itemsPerPage,
          "key": this.selectKey,
          [this.keyName]: this.keyValue,
          [this.keyName1]: this.keyValue1
        }
        this.filterProps = tempPayload1
      } else if (this.keyName != '') {
        tempPayload2 = {
          "fromDate": this.filterQuery ? this.filterQuery['fromDate'] : null,
          "toDate": this.filterQuery ? this.filterQuery['toDate'] : null,
          "pageNo": this.page ?? 0,
          "pageSize": this.itemsPerPage,
          "key": this.selectKey,
          [this.keyName]: this.keyValue
        }
        this.filterProps = tempPayload2
      } else {
        tempPayload3 = {
          "fromDate": this.filterQuery ? this.filterQuery['fromDate'] : null,
          "toDate": this.filterQuery ? this.filterQuery['toDate'] : null,
          "pageNo": this.page ?? 0,
          "pageSize": this.itemsPerPage,
          "key": this.selectKey,
          "levelMasterId": levelMasterId
        }
        this.filterProps = tempPayload3
      }
    }

    //if(!filterQuery){
    // filterQuery = {}
    //}
    //if(this.currentElement.queryparms)
    this.reportService
      .getData(
        { ...this.filterQuery },
        this.filterProps
      )
      .subscribe(
        (res: HttpResponse<any[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
          this.spinner.hide()
        }
      );
  }

  protected onSuccess(
    data: any[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    console.log(data,'data');
    this.searchResult.total = data ? data['totalRecordCount'] : null;
    this.searchResult.totalList = data['totalData'] ?? '';
    this.spinner.hide();
    if (data) {
      this.searchResult.items = data['data'] ?? [];
    } else {
      this.searchResult.items = []
    }
    this.searchResult = { ...this.searchResult };
  }
  protected onError(): void { }
  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    console.log(event);
    
    this.loadPage(event.page - 1);
  }
  back() {
    if (this.currentElement?.report_heading_name == 'District Wise Report') {
      this.loadData('DistrictWiseReport')
    } else if (this.currentElement?.report_heading_name == 'Requesting Agency Wise Report') {
      this.loadData('ReqAgencyWiseReport')
    } else if (this.currentElement?.report_heading_name == 'Beneficiary Wise District Report') {
      this.loadData('BenificiaryWiseReport')
    } else if (this.currentElement?.report_heading_name == 'Beneficiary Report') {
      this.loadData('BenificiaryWiseReport')
    } else if (this.currentElement?.report_heading_name == 'Project Wise District Report') {
      this.loadData('ProjectWiseReport')
    } else if (this.currentElement?.report_heading_name == 'Payment Status Report') {
      this.loadData(this.selectTab)
    } else if (this.currentElement?.report_heading_name == "Amount Transactions Report") {
      this.loadData(this.selectTab)
    } else if (this.currentElement?.report_heading_name == 'Day Wise Payment Beneficiaries Report' || this.currentElement?.report_heading_name == 'Day Wise Payment Details Report') {
      this.loadData('DayWisePaymentReport')
    }
  }

  clear(data) {
    this.fromDate = ''
    this.toDate = ''
    if (data == 'Project Wise Report') {
      delete this.filterQuery['fromDate']
      delete this.filterQuery['toDate']
      this.loadPage(this.page)
    } else {
      // this.filterQuery['fromDate'] = this.datePipe.transform(new Date("01-01-2023"), 'yyyy-MM-dd');
      // this.filterQuery['toDate'] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.loadPage(this.page)
    }

  }
  dynamicReport(event) {

    if (this.currentElement?.report_heading_name == 'District Wise Report') {
      this.keyName = 'levelMasterId',
        this.keyValue = event.value.levelMasterId
      if (event.name == "noOfDepartments") {
        this.loadData('DistrictWiseDepartmentReport', 'isBack')
        this.drillDown = true
      } else if (event.name == "noOfProjects" && this.drillDown) {
        this.keyName1 = "agencyId",
          this.keyValue1 = event.value.agencyId
        this.loadData('DistrictWiseProjectReport', 'isBack')
      } else if (event.name == "noOfProjects" && !this.drillDown) {
        this.loadData('DistrictWiseProjectReport', 'isBack')
      }
    } else if (this.currentElement?.report_heading_name == 'Requesting Agency Wise Report') {
      this.drillDown = false
      this.keyName = 'agencyId',
        this.keyValue = event.value.agencyId
      if (event.name == "noOfProjects") {
        this.loadData('ReqAgencyWiseProjectReport', 'isBack')
      } else if (event.name == "noOfDistricts") {
        this.keyName1 = 'projectId',
          this.keyValue1 = event.value.projectId
        this.loadData('ReqAgencyWiseDistrictReport', 'isBack')
      }
    }
    else if (this.currentElement?.report_heading_name == 'Day Wise Payment Report') {
      this.drillDown = false
      console.log(event, 'eventevent');
      if (event.name == "projectName") {
        this.keyName = 'projectId',
          this.keyValue = event.value.projectId
        this.loadData('DayWisePaymentBenificiaryReport', 'isBack')
      }
    }
    else if (this.currentElement?.report_heading_name == 'Project Wise Report') {
      this.drillDown = false
      if (event.name == "district") {
        this.keyName = 'projectId',
          this.keyValue = event.value.projectId
        this.loadData('ProjectWiseDistrictReport', 'isBack')
      }
    }
    else if (this.currentElement?.report_heading_name == 'Day Wise Payment Beneficiaries Report') {
      if (event.name == "noOfBenificiaryAwarded") {
        this.keyName = 'projectId',
          this.keyValue = event.value.projectId
        this.loadData('DayWisePaymentDetailsReport', 'isBack')
      }
    }
    else if (this.currentElement?.report_heading_name == 'Beneficiary Wise Report') {
      this.drillDown = false
      this.keyName1 = ''
      if (event.name == "projectName") {
        this.keyName = 'projectId',
          this.keyValue = event.value.projectId
        this.loadData('BenificiaryWiseDistrictReport', 'isBack')
      }
    }
    else if (this.currentElement?.report_heading_name == 'Beneficiary Wise District Report') {
      this.drillDown = false
      this.keyName1 = ''
      if (event.name == "noOfBenificiary") {
        this.keyName = 'projectId',
          this.keyValue = event.value.projectId
        this.loadData('BenificiaryWiseNameReport', 'isBack')
      }
    } else if (this.currentElement?.report_heading_name == 'Account Details Report') {
      this.drillDown = false
      this.keyName1 = ''
      if (event.name == "accountNumber") {
        this.keyName = 'projectId',
          this.keyValue = event.value.projectId
        this.loadData('VirtualTransactionHistoryReport', 'isBack')
      }
    } else if (this.currentElement?.report_heading_name == 'Voucher Status Report' || this.currentElement?.report_heading_name == 'Payment Success Report' || this.currentElement?.report_heading_name == 'Payment Failure Report' || this.currentElement?.report_heading_name == 'Payment Pending Report') {
      this.drillDown = false
      this.keyName1 = ''
      if (event.name == "projectName") {
        this.keyName = 'projectId',
          this.keyValue = event.value.projectId
        this.loadData('VoucherStatusDetailsReport', 'isBack')
      }
    }
  }
  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }
}

