<div class="row">
  <div class="col-lg-6 col-sm-6 col-12 left-content">
    <div>
      <div class="login">
        <img class="tn-logo" src="/./assets/img/ib-rdp-tng-logo.svg" alt="Image" />
        <h4>Government of Tamil Nadu</h4>
        <h3 class="text-uppercase">Commissionerate of Land Administration</h3>
        <div class="laaten-content">
          <h5 class="text-center text-uppercase">Land Acquisition Agency of Tamil Nadu</h5>
        </div>
        <h5>{{ !showOtp ? "Sign In" : "OTP Verification" }}</h5>
        <p>Single Nodal <span>Account</span></p>
      </div>
      <div class="mt-3">
        <div class="login-content" *ngIf="!showOtp">
          <form class="form" role="form" (ngSubmit)="login()" [formGroup]="loginForm">
            <div class="form-group mb-4">
              <label htmlFor="username">Username</label>
              <input id="username" type="text" id="orangeForm-email" class="form-control full"
                formControlName="username" uppercase name="username" autocomplete="off" />
            </div>
            <label htmlFor="username">Password</label>
            <button type="button" (click)="navToForgotPassword()" class="btn btn-link btn-sm forgotPassword">
              Forgot Password?
            </button>
            <div class="form-group">
              <input id="password" name="password" autocomplete="off" formControlName="password" id="orangeForm-pass"
                class="form-control full" [type]="hidden ? 'password' : 'text'" />
              <i class="pi pi-eye eye" *ngIf="hidden" (click)="hidden = false"></i>
              <i class="pi pi-eye-slash eye" *ngIf="!hidden" (click)="hidden = true"></i>
            </div>
            <div *ngIf="passwordError" class="mt-2">
              <small style="font-size: 30px;" class="text-danger">{{passwordError}}</small>
            </div>
            <div *ngIf="inValidUser" class="mb-4">
              <small class="text-danger">Please enter valid username and password</small>
            </div>
            <div class="row mb-2">
              <div class="col-7">
                <div>
                  <label htmlFor="username">Enter Captcha </label>
                  <input id="captcha" type="text" id="captcha" class="form-control full" formControlName="captcha"
                    oninput="this.value = this.value.replace(/[^0-9A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                    onblur="this.value =this.value.trim();" />
                  <div *ngIf="inValidCaptcha" class="text-end mt-2">
                    <small class="text-danger">Incorrect Captcha.Please try again..</small>
                  </div>
                  <div *ngIf="userNameError" class="mt-2">
                    <small style="font-size: 30px;" class="text-danger">{{userNameError}}</small>
                  </div>
                  <div *ngIf="captchaExpireError" class="text-end mt-2">
                    <small class="text-danger">{{captchaExpireError}}</small>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div class="row mt-4">
                  <div class="col-9">
                    <div class="text-center" *ngIf="spinners">
                      <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                    <div *ngIf="!spinners">
                      <img class="img" [src]="image" />
                    </div>
                  </div>
                  <div class="col-3">
                    <i class="pi pi-refresh pt-2" style="font-size: 1.2rem" (click)="loadCaptcha()"></i>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="lockedError">
              <small style="font-size: 30px;" class="text-danger">{{lockedError}}</small>
            </div>
            <div class="mb-4 d-flex justify-content-center">
              <button type="submit" class="btn btn-color">Login</button>
            </div>
          </form>
        </div>
        <div class="login-content" *ngIf="showOtp">
          <form class="form mt-4" role="form" (ngSubmit)="loginOtp()" [formGroup]="otpForm">
            <div class="form-group mb-2">
              <input id="otp" name="otp" maxlength="6" autocomplete="off" formControlName="otp" type="text"
                class="form-control full" placeholder="ENTER OTP" />
              <div *ngIf="otpError" class="mt-2">
                <small style="font-size: 30px" class="text-danger">{{
                  otpError
                  }}</small>
              </div>
            </div>
            <div class="mb-4">Resend OTP in: {{ otpTimer }} seconds</div>
            <div class="row">
              <div class="col">
                <button type="submit" [disabled]="enableResendButton" class="btn btn-secondary">Sign In</button>
              </div>
              <div class="col text-end">
                <button type="button" [disabled]="!enableResendButton" (click)="getUserOtp('reSend')"
                  class="btn btn-secondary">
                  Resend OTP
                </button>
              </div>
            </div>
            <div class="footer-logo mt-2">
              <button type="button" (click)="navToLogin()" class="btn btn-link btn-sm back_login">
                Back to Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="content-footer">
      <img style="float: right;" src="../../../assets/img/IOB.png" width="150px" height="50px" alt="">
    </div>
  </div>
  <div class="col-lg-6 col-sm-6 laatnBg">
    <div>
      <img src="../../../assets/img/bg.jpeg" class="w-100" style="height: 100vh;" alt="" />
    </div>
  </div>
</div>