<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5>{{currentElement?.report_heading_name}}</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row px-4 g-2">
        <!-- <div *ngIf="currentElement?.enable_District" >
                <label class="form-control-label" for="scheme">District</label>
                <ng-select id="scheme" bindLabel="name" [(ngModel)]="district" [items]="this.districtLevelList"
                    appearance="outline" [closeOnSelect]="true" (change)="onDistrictLevelChange()" [clearable]="false"
                    class="form-control">
                </ng-select>
            </div>
            <div *ngIf="currentElement?.enable_Block" >
                <label class="form-control-label" for="scheme">Block</label>
                <ng-select id="scheme" [(ngModel)]="BlockLevelDto" [items]="blockLevelList" bindLabel="name"
                    appearance="outline" [closeOnSelect]="true" [clearable]="false" class="form-control">
                </ng-select>
            </div> -->
            <div class="row">
                <div *ngIf="currentElement?.enable_date" class="col-sm-2">
                    <label class="form-control-label" for="dateOfBirth">From Date</label><br />
                    <p-calendar dateFormat="dd-mm-yy" [readonlyInput]="true" [(ngModel)]="fromDate" [maxDate]="maxDate"  [minDate]="minDate"
                        styleClass="datepicker"></p-calendar>
                </div>
                <div *ngIf="currentElement?.enable_date" class="col-sm-2">
                    <label class="form-control-label" for="dateOfBirth">To Date</label><br />
                    <p-calendar dateFormat="dd-mm-yy" [readonlyInput]="true" [(ngModel)]="toDate" [maxDate]="maxDate" [minDate]="minDate"
                        styleClass="datepicker"></p-calendar>
                </div>
                <div *ngIf="currentElement?.enable_search" class="col-sm-2 pt-3">
                    <p-button [disabled]="fromDate =='' ||  toDate =='' " (click)="loadPage(page)" class="p-button-primary align-items-end mt-2 pt-3">
                        <span class="button-label">Search</span>
                    </p-button> &nbsp;
                    <p-button [disabled]="fromDate =='' ||  toDate =='' " (click)="clear(currentElement?.report_heading_name)" class="p-button-primary align-items-end mt-2 pt-3">
                        <span class="button-label">Clear</span>
                    </p-button>
                </div>
            </div>

        <!-- 
            <div *ngIf="currentElement?.enable_finyear" >
                <label class="form-control-label req" for="date" jhiTranslate="funds.fin.year.name">Fin Year</label>
                <ng-select id="gender" bindLabel="name" [items]="finYear" appearance="outline" [(ngModel)]="finYearDto"
                    [closeOnSelect]="true" [clearable]="false" class="form-control">
                </ng-select>
            </div> -->

        <div *ngIf="isBack" class="pt-2">
            <p-button (click)="back()" class="backBtn">
                <span class="button-label">Back</span>
            </p-button>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [fileName]="currentElement?.report_heading_name" [pageNo]="page"
                [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [keyName]="selectTab" [firstPage]="firstPage"
                (dynamicParam)="dynamicReport($event)" [disableDeleteIcon]="!pageCRUDdata?.crud.delete" [back]="isBack"
                [showProfileButton]="false" [disableEditIcon]="!pageCRUDdata?.crud.update"
                [enableView]="pageCRUDdata?.crud.read"></custom-filter-table>
        </div>
    </div>
</div>