<div class="Main">
  <div class="container-fluid  mt-3">
    <div class="d-flex bd-highlight">
      <div class="p-2 flex-grow-1 bd-highlight">
        <button class="head_btn" (click)="listName = 'over'">Overall</button>
        <button class="head_btn" *ngIf="roleCode == 'SA'"  (click)="getAbstract('oversna')">Overall SNA</button>
        <button class="head_btn">Day Wise</button>
        <button class="head_btn" (click)="getAbstract('agency');">Requisitioning Body Wise</button>
        <button class="head_btn" (click)="getAbstract('district')">District Wise</button>
      </div>
      <div class="p-2 bd-highlight">
        <p-calendar dateFormat="dd-mm-yy" [(ngModel)]="fromDate" placeholder="From Date"
          styleClass="datepicker"></p-calendar>
        <p-calendar dateFormat="dd-mm-yy" [minDate]="fromDate" [(ngModel)]="toDate" class="mx-2" placeholder="To Date"
          styleClass="datepicker"></p-calendar>
        <button class="btn btn-primary" [disabled]="fromDate == '' || toDate == ''" (click)="search('search')">Search
        </button> &nbsp;
        <button class="btn btn-danger" [disabled]="fromDate == '' || toDate == ''" (click)="search()">clear
        </button>
      </div>
    </div>
    <div *ngIf="listName == 'over'">
      <div class="card top">
        <div class="row g-xl-3">
          <div class="col-lg-3">
            <div class="card list1" style="cursor: pointer;" (click)="noOfProject()">
              <div class="row">
                <div class="col-lg-6 bl1">
                  <p>No of Projects</p>
                  <h3>{{this.fundDtails?.noOfProjects}}</h3>
                </div>
                <div class="col-lg-6">
                  <img src="../../../assets/img/Background.png" style="float: right;" alt="">
                </div>
                <p class="mt-2">
                  <span class="increase">{{this.fundDtails?.noOfProjectsOnLastMonth}} </span>Last Month
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card list2" style="cursor: pointer;" (click)="fund('receive')">
              <div class="row ">
                <div class="col-lg-8 bl2">
                  <p>Fund Received(In Crores)</p>
                  <h3>{{this.fundDtails?.totalFundReceived / 10000000 | number: '1.1-4'}}</h3>
                </div>
                <div class="col-lg-4">
                  <img src="../../../assets/img/Background (1).png" style="float: right;" alt="">
                </div>
                <p class="mt-2"> <span class="increase">
                    {{this.fundDtails?.fundReceivedOnLastMonth}}
                  </span> From last Month</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card list3" style="cursor: pointer;" (click)="expenditure()">
              <div class="row ">
                <div class="col-lg-8 bl3">
                  <p>Expenditure(In Crores)</p>
                  <h3>{{this.fundDtails?.totalFundSpent / 10000000 | number: '1.4-4'}}</h3>
                </div>
                <div class="col-lg-4">
                  <img src="../../../assets/img/Background (2).png" style="float: right;" alt="">
                </div>
                <p class="mt-2"> <span class="increase"> {{this.fundDtails?.expenditureOnLastMonth}}
                  </span>From last Month</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card list4" (click)="fund('balance')">
              <div class="row ">
                <div class="col-lg-8 bl4">
                  <p>Closing Balance(In Crores)</p>
                  <h3>{{this.fundDtails?.totalAvailableAmount / 10000000 |number: '1.4-4'}}</h3>
                </div>
                <div class="col-lg-4">
                  <img src="../../../assets/img/Overlay.png" style="float: right;" alt="">
                </div>
                <p class="mt-2"> <span class="increase"> {{this.fundDtails?.balanceOnLastMonth }}
                  </span> From last Month</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1 g-xl-2 ">
        <div class="col-lg-4">
          <div class="card p-3">
            <h2 class="head">Districts Vs No of Beneficiary</h2>
            <div id="chart">
              <apx-chart *ngIf="isShowFirstChart" [series]="chartOption.series" [chart]="chartOption.chart"
                [dataLabels]="chartOption.dataLabels" [plotOptions]="chartOption.plotOptions"
                [xaxis]="chartOption.xaxis" [stroke]="chartOption.stroke" [fill]="chartOption.fill"
                [yaxis]="chartOption.yaxis" [title]="chartOption.title" [tooltip]="chartOption.tooltip"
                [legend]="chartOption.legend"></apx-chart>
            </div>
            <!-- <canvasjs-chart *ngIf="isShowFirstChart" [options]="chartOptions"
              [styles]="{width: '100%', height:'360px'}"></canvasjs-chart> -->
          </div>
        </div>
        <div class="col-lg-4">
          <div class="month" style="height: 430px;">
            <h2 class="head mt-2">Month Wise Expenditure Made </h2>
            <p-chart type="line" height="370px" [data]="data" [options]="options"></p-chart>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card p-3" style="height: 430px;">
            <h2 class="head">Fund Shortage Details</h2>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Project</th>
                  <th scope="col">Available Amount</th>
                  <th scope="col">Available Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of fundShortageDetails">
                  <td>{{data.projectName}}</td>
                  <td>{{data.availableAmount |indianRupee}}</td>
                  <td>{{data.availablePercent}}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-center" *ngIf="fundShortageDetails == ''">
              <img src="../../../assets/img/notFound.png" width="350px" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1 g-xl-2">
        <div class="col-lg-4">
          <div class="card p-3">
            <h2 class="head">Fund Details </h2>
            <div id="charts">
              <apx-chart *ngIf="fundDetails" [series]="chartOptions.series" [chart]="chartOptions.chart"
                [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions"
                [responsive]="chartOptions.responsive" [xaxis]="chartOptions.xaxis" [legend]="chartOptions.legend"
                [fill]="chartOptions.fill"></apx-chart>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card p-3">
            <h2 class="head">Disbursement Details </h2>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Requisitioning Agency</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of disbursementDetails">
                  <td>{{data.agencyName }}</td>
                  <td>{{data.expenditure | indianRupee}}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-center" *ngIf="disbursementDetails == ''">
              <img src="../../../assets/img/notFound.png" width="350px" alt="">
            </div>
          </div>

        </div>
      </div>
    </div>
    <div *ngIf="listName == 'over'">
      <p-dialog header="Agency Expenditure List" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Expenditure</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of agencyDetails">
              <td>{{data.name}}</td>
              <td>{{data.expenditure |indianRupee}}</td>
            </tr>
          </tbody>
        </table>
      </p-dialog>
      <p-dialog header="No of Projects" [modal]="true" [(visible)]="noOfProjects" [style]="{ width: '100rem' }">
        <div class="row">
          <div class="col-lg-6">
            <table class="table ">
              <thead>
                <tr>
                  <th scope="col">Agency</th>
                  <th scope="col">Total No of Projects</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of getNoOfProjects;let i=index">
                  <td>
                    {{data.agencyName}}
                  </td>
                  <td>
                    <span class="count" (click)="getData(i)">{{data.totalNoOfProjects}}</span>
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{{totalcount}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-6">
            <div class="card p-1">
              <p-chart type="pie" *ngIf="showPie" [data]="dataforPie" width="400px" [options]="optionsforPie"></p-chart>
            </div>
            <div class="mt-3">
              <table class="table" *ngIf="selectProject">
                <thead>
                  <tr>
                    <th scope="col">Project Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of selectProject">
                    <td>{{data.name}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </p-dialog>
      <p-dialog header="{{flag =='receive' ? 'Fund Received':'Fund Balance'}}" [modal]="true"
        [(visible)]="isFundReceived" [style]="{ width: '65rem' }">
        <div class="row">
          <p-table [value]="fundReceivedData" dataKey="agencyId" [expandedRowKeys]="expandedRows"
            (onRowCollapse)="onRowCollapse($event)" (onRowExpand)="onRowExpand($event)"
            [tableStyle]="{ 'min-width': '60rem' }">
            <ng-template pTemplate="header">
              <tr>
                <th class="thead" style="width: 5rem"></th>
                <th class="thead">Agency Name</th>
                <th class="thead">No of Projects</th>
                <th class="thead">Fund Received</th>
                <th class="thead" *ngIf="flag =='balance'">Fund Disbursed</th>
                <th class="thead" *ngIf="flag =='balance'">Balance Fund Available</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-fund let-expanded="expanded" let-index="rowIndex">
              <tr>
                <td>
                  <button pButton severity="secondary" type="button" type="button" [pRowToggler]="fund" text="true"
                    rounded="true" plain="true"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td>{{ fund.agencyName }}</td>
                <td>{{ fund.noOfProjects }}</td>
                <td>{{ fund.fundReceivedForAgency|indianRupee }}</td>
                <td *ngIf="flag =='balance'">{{ fund.fundDisbursedByAgency|indianRupee }}</td>
                <td *ngIf="flag =='balance'">{{ fund.balanceAvailable|indianRupee }}</td>
              </tr>
              <tr *ngIf="(index == this.fundReceivedDataLength)">
                <td class="bg text-center" colspan="2">Total</td>
                <td class="bg">{{noProject}}</td>
                <td class="bg">{{totalAmountReceived |indianRupee}}</td>
                <td class="bg" *ngIf="flag =='balance'">{{amountDisburse |indianRupee}}</td>
                <td class="bg" *ngIf="flag =='balance'">{{amountBalance |indianRupee}}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-fund>
              <tr>
                <td colspan="9">
                  <div class="p-3">
                    <p-table [value]="fund.projects" dataKey="projectId">
                      <ng-template pTemplate="header">
              <tr>
                <th class="thead"></th>
                <th class="thead">Agency Name </th>
                <th class="thead">Project Name </th>
                <th class="thead">Fund Received </th>
                <th class="thead" *ngIf="flag == 'balance'">Fund Spent </th>
                <th class="thead" *ngIf="flag == 'balance'">Fund Balance </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order let-expandeds="expanded">
              <tr>
                <td>
                  <button pButton type="button" [pRowToggler]="order"
                    [icon]="expandeds ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td>
                  {{order.agencyName}}
                </td>
                <td (mouseover)="over(order)">
                  <span pTooltip="{{ tooltipContent }}" tooltipPosition="top"> {{order.projectName}}</span>
                </td>
                <td>
                  {{order.fundReceived |indianRupee}}
                </td>
                <td *ngIf="flag == 'balance'">
                  {{order.fundSpent |indianRupee}}
                </td>
                <td *ngIf="flag == 'balance'">
                  {{order.fundBalance |indianRupee}}
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="rowexpansion" let-order>
              <tr>
                <td colspan="7">
                  <div class="p-3">
                    <p-table [value]="order.districts" dataKey="id">
                      <ng-template pTemplate="header">
              <tr>
                <th class="thead">Districts</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
              <tr>
                <td>{{ order.name }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="6">There are no order for this product yet.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        </td>
        </tr>
        </ng-template>
        </p-table>
    </div>
    </td>
    </tr>
    </ng-template>
    </p-table>
  </div>

  </p-dialog>
  <p-dialog header="Fund Expenditure" [modal]="true" [(visible)]="isFundExpenditure" [style]="{ width: '90rem' }">
    <div class="row">
      <p-tabView>
        <p-tabPanel header="Requisitioning Body Wise">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Name of the Requisitioning Body</th>
                <th scope="col">Total No of Projects</th>
                <th scope="col">Total Fund Received</th>
                <th scope="col">No of Projects Disbursement Done</th>
                <th scope="col">Spent</th>
                <th scope="col">Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of expenditureList?.requestioningBody.requestioningBodyAbstract">
                <td><span class="highlight" (click)="getDrillDown(data,'agency')">{{data?.agencyName}}</span></td>
                <td>{{data?.noOfProjects}}</td>
                <td>{{data?.totalAmountReceived | indianRupee}}</td>
                <td>{{data?.noOfProjectsDonePayment}}</td>
                <td>{{data?.totalAmountSpent |indianRupee}}</td>
                <td>{{data?.totalBalance |indianRupee}}</td>
              </tr>
              <tr class="bg">
                <td>
                  Total
                </td>
                <td>
                  {{expenditureList?.requestioningBody.totalNoOfProjects}}
                </td>
                <td>
                  {{expenditureList?.requestioningBody.totalFundReceived |indianRupee}}
                </td>
                <td>
                  {{expenditureList?.requestioningBody.totalProjectsDisbursementDone}}
                </td>
                <td>
                  {{expenditureList?.requestioningBody.totalFundSpent |indianRupee }}
                </td>
                <td>
                  {{expenditureList?.requestioningBody.totalFundBalance |indianRupee}}
                </td>
              </tr>
            </tbody>
          </table>
        </p-tabPanel>
        <p-tabPanel header="District Wise">
          <table class="table district">
            <thead>
              <tr>
                <th scope="col">District Name</th>
                <th scope="col">Total No of Projects</th>
                <!-- <th scope="col">Total Fund Received</th> -->
                <th scope="col">No of Projects Disbursement Done</th>
                <th scope="col">Spent</th>
                <!-- <th scope="col">Balance</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of expenditureList?.district.districtAbstract">
                <td> <span class="highlight" (click)="getDrillDown(data,'district')">{{data?.districtName}}</span></td>
                <td>{{data?.noOfProjects}}</td>
                <!-- <td>{{data?.totalAmountReceived | indianRupee}}</td> -->
                <td>{{data?.noOfProjectsDonePayment}}</td>
                <td>{{data?.totalAmountSpent |indianRupee}}</td>
                <!-- <td>{{data?.totalBalanceAmount |indianRupee}}</td> -->
              </tr>
              <tr class="bg">
                <td>
                  Total
                </td>

                <td>
                </td>
                <td>
                </td>
                <td>
                  {{expenditureList?.district.totalFundSpent |indianRupee}}
                </td>
                <!-- <td>
                </td> -->
              </tr>
            </tbody>
          </table>
        </p-tabPanel>
      </p-tabView>
    </div>
  </p-dialog>
  <p-dialog header="Project Details" [modal]="true" [(visible)]="agencyPopup" [style]="{ width: '90rem' }">
    <div>
      <table class="table ">
        <thead>
          <tr>
            <th rowspan="2">S.No</th>
            <th rowspan="2">Project</th>
            <th rowspan="2">Fund Received </th>
            <th rowspan="2">Pending Extent</th>
            <th rowspan="2">Extent Acquired</th>
            <th rowspan="2">No of Beneficiary </th>
            <th rowspan="2" class="text-center">No of Subdivision Award</th>
            <th colspan="4" class="text-center">
              Disbursement Details
            </th>
            <th rowspan="2">
              Total Disbursement
            </th>
            <th colspan="2" class="text-center">
              Balance
            </th>
            <th rowspan="2">% Beneficiary Pay</th>
            <th rowspan="2">% Court Pay </th>
          </tr>
          <tr>
            <th>Amount Spent Beneficiary </th>
            <th>No of Beneficiary Award </th>
            <th>Court Pay</th>
            <th>No of Court Beneficiary </th>
            <th>Pending Beneficiary</th>
            <th>Balance Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of agencyList;let i=index">
            <td>{{i+1}}</td>
            <td>{{data.projectName}}</td>
            <td>{{data.fundReceived |indianRupee}}</td>
            <td>{{data.pendingExtent}}</td>
            <td>{{data.acquiredExtent}}</td>
            <td>{{data.noOfBeneficiaries}}</td>
            <td>{{data.noOfSubDivisionsAwarded}}</td>
            <td>{{data.amountDisbursedToBeneficiary}}</td>
            <td>{{data.noOfBeneficiariesAwarded}}</td>
            <td>{{data.amountDisbursedInCourt}}</td>
            <td>{{data.noOfBeneficiariesInCourtDeposit}}</td>
            <td>{{data.totalFundDisbursed}}</td>
            <td>{{data.noOfPendingBeneficiaries}}</td>
            <td>{{data.balanceFundsAvailable | indianRupee}}</td>
            <td>{{data.percentOfBeneficariesAwarded}}</td>
            <td>{{data.percentOfBeneficairiesInCourtDeposit}}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </p-dialog>
</div>
<div *ngIf="listName == 'oversna'" class="container">
  <table class="table overallSNA">
    <thead>
      <tr>
        <th colspan="2" scope="col"><h2  class="text-center m-0">Overall Abstract of SNA</h2> </th>
      </tr> 
    </thead>
    <tbody class="text-center">
      <tr> 
        <th scope="row" class="p-4">Total No of Projects</th>
        <td class="p-4">{{overallSNA?.noOfProjects}}</td>
      </tr>
      <tr>
        <th scope="row" class="p-4">Total Amount Received</th>
        <td class="p-4">{{overallSNA?.totalAmountReceived |indianRupee}}</td>
      </tr>
      <tr>
        <th scope="row" class="p-4">Interest Amount</th>
        <td class="p-4">{{overallSNA?.interestAmount |indianRupee}}</td>
      </tr>
      <tr>
        <th scope="row" class="p-4 table-info">Total Amount Value in SNA</th>
        <td class="table-info p-4">{{overallSNA?.totalAmount |indianRupee}}</td>
      </tr>
      <tr>
        <th scope="row" class="p-4">Compensation Disbursed to Beneficiaries</th>
        <td class="p-4">{{overallSNA?.compensationToBeneficiaries | indianRupee}}</td>
      </tr>
      <tr>
        <th scope="row" class="p-4">Compensation Deposited in court through SNA</th>
        <td class="p-4">{{overallSNA?.compensationToCourt |indianRupee}}</td>
      </tr>
      <tr>
        <th scope="row" class="p-4">Compensation Returned</th>
        <td class="p-4">{{overallSNA?.compensationReturned | indianRupee}}</td>
      </tr>
      <tr>
        <th scope="row" class="p-4 table-success">Total Compensation spent through SNA</th>
        <td class="table-success p-4">{{overallSNA?.totalSpent | indianRupee}}</td>
      </tr>
      <tr>
        <th scope="row" class="p-4 table-danger">Total Balance Amount Available</th>
        <td class="table-danger p-4">{{overallSNA?.totalBalance |indianRupee}}</td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="listName == 'agency'" class="container">
  <h2>Requisitioning Body Wise Abstract</h2>
  <div class="bgFilter">
    <p-multiSelect [options]="agencyListForFilter" [(ngModel)]="agencyFilter" optionLabel="agencyName"
      selectedItemsLabel="{0} agencies selected" [style]="{minWidth: '100px'}" showClear="true"
      placeholder="Select Agency"></p-multiSelect>
    &nbsp;
    <button class="btn btn-primary" (click)="changeValue('agency')">Submit
    </button>&nbsp;
    <button class="btn btn-danger " (click)="getAbstract('agency')">Clear
    </button>
  </div>
  <div class="tableForAgency">
    <p-table [value]="agencyListAbstract" dataKey="agencyId" [expandedRowKeys]="expandedRows"
      (onRowCollapse)="onRowCollapse($event)" (onRowExpand)="onRowExpand($event)"
      [tableStyle]="{ 'min-width': '70rem'}">
      <ng-template pTemplate="header">
        <tr class="thead">
          <th class="thead" style="width: 5rem"></th>
          <th class="thead">Agency Name</th>
          <th class="thead">No of Projects</th>
          <th class="thead">No of Beneficiary</th>
          <th class="thead">No of Districts</th>
          <th class="thead">Fund Disbursed</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-fund let-expanded="expanded">
        <tr>
          <td>
            <button pButton type="button" [pRowToggler]="fund" class="btnForExpand" text="true" rounded="true"
              plain="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td>{{ fund.agencyName }}</td>
          <td>{{ fund.noOfProjects }}</td>
          <td>{{ fund.noOfBeneficiaries }}</td>
          <td>{{ fund.noOfDistricts }}</td>
          <td>{{ fund.amountDisbursed|indianRupee }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-fund>
        <tr>
          <td colspan="9">
            <div class="p-3">
              <p-table [value]="fund.projects" dataKey="projectId">
                <ng-template pTemplate="header">
        <tr>
          <th class="thead"></th>
          <th class="thead">Project Name </th>
          <th class="thead">No of Districts </th>
          <th class="thead">Fund Spent </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-order let-expandeds="expanded">
        <tr>
          <td>
            <button pButton type="button" [pRowToggler]="order"
              [icon]="expandeds ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>

          <td>
            {{order.projectName}}
          </td>
          <td>
            {{order.noOfDistricts}}
          </td>
          <td>
            {{order.fundSpent |indianRupee}}
          </td>

        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-order>
        <tr>
          <td colspan="7">
            <div class="p-3">
              <p-table [value]="order.districts" dataKey="id">
                <ng-template pTemplate="header">
        <tr>
          <th class="thead">S.No</th>
          <th class="thead">Districts</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-order let-rowIndex="rowIndex">
        <tr>
          <td>{{rowIndex +1}}</td>
          <td>{{ order.name }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6">There are no order for this product yet.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  </td>
  </tr>
  </ng-template>
  </p-table>
</div>
</td>
</tr>
</ng-template>
</p-table>
</div>
</div>

<div *ngIf="listName == 'district'" class="container">
  <h2>District Wise Abstract</h2>
  <div class="bgFilter">
    <p-multiSelect [options]="districtListForFilter" [(ngModel)]="districtFilter" optionLabel="name"
      selectedItemsLabel="{0} districts selected" [style]="{minWidth: '100px'}" showClear="true"
      placeholder="Select District"></p-multiSelect>
    &nbsp;
    <button class="btn btn-primary" (click)="changeValue('district')">Submit
    </button>&nbsp;
    <button class="btn btn-danger " (click)="getAbstract('district')">Clear
    </button>
  </div>
  <div class="tableForAgency">
    <p-table [value]="districtListAbstract" dataKey="districtId" [expandedRowKeys]="expandedRows"
      (onRowCollapse)="onRowCollapse($event)" (onRowExpand)="onRowExpand($event)"
      [tableStyle]="{ 'min-width': '70rem'}">
      <ng-template pTemplate="header">
        <tr class="thead">
          <th class="thead" style="width: 5rem"></th>
          <th class="thead">District Name</th>
          <th class="thead">No of Projects</th>
          <th class="thead">No of Beneficiary</th>
          <th class="thead">No of Agency</th>
          <th class="thead">Fund Disbursed</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-fund let-expanded="expanded">
        <tr>
          <td>
            <button pButton type="button" class="btnForExpand" [pRowToggler]="fund" text="true" rounded="true"
              plain="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td>{{ fund.districtName }}</td>
          <td>{{ fund.noOfProjects }}</td>
          <td>{{ fund.noOfBeneficiariesAwarded }}</td>
          <td>{{ fund.noOfAgencies }}</td>
          <td>{{ fund.amountDisbursed|indianRupee }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-fund>
        <tr>
          <td colspan="9">
            <div class="p-3">
              <p-table [value]="fund.drillDown" dataKey="agencyId">
                <ng-template pTemplate="header">
        <tr>
          <th class="thead">Agency Name </th>
          <th class="thead">Project Name </th>
          <th class="thead">No of Beneficiary </th>
          <th class="thead">Fund Spent </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-order let-expandeds="expanded">
        <tr>
          <td>{{
            order.agencyName
            }}
          </td>
          <td>
            {{order.projectName}}
          </td>
          <td>
            {{order.noOfBeneficiariesAwarded}}
          </td>
          <td>
            {{order.amountDisbursedByAgency |indianRupee}}
          </td>

        </tr>
      </ng-template>

    </p-table>
  </div>
  </td>
  </tr>
  </ng-template>
  </p-table>
</div>
</div>
</div>
</div>