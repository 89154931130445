
import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardOldComponent implements OnInit {
	chart: any;
	isButtonVisible = false;
	fundDetails: any;
	districts: any;
	tempDistrictArr: any = [];
	isShowPie: boolean;
	projectList: any;
	projectTemp: any = [];
	districtName: any;
	dataBar: any;
	optionsBar: any;
	dataSideBar: any;
	optionsSideBar: any;
	requestingDepartments: any;
	sampleDepartments: any[];
	getLand: any
	sampleLands: any = [];
	optionsProject: any
	dataProject: { labels: any; datasets: { label: string; backgroundColor: string; borderColor: string; data: unknown; }[]; };
	projectValues: any;
	dataProjectValue: { labels: any; datasets: { label: string; backgroundColor: string; borderColor: string; data: any; }[]; };
	optionsProjectValue: { maintainAspectRatio: boolean; aspectRatio: number; plugins: { legend: { labels: { color: string; }; }; }; scales: { x: { ticks: { color: string; font: { weight: number; }; }; grid: { color: string; drawBorder: boolean; }; }; y: { ticks: { color: string; }; grid: { color: string; drawBorder: boolean; }; }; }; };
	AmountReceivedata:any
	AmountReceiveOptions: { maintainAspectRatio: boolean; aspectRatio: number; plugins: { legend: { labels: { color: string; }; }; }; scales: { x: { ticks: { color: string; }; grid: { color: string; drawBorder: boolean; }; }; y: { ticks: { color: string; }; grid: { color: string; drawBorder: boolean; }; }; }; };
	AmountSpentOptions: { maintainAspectRatio: boolean; aspectRatio: number; plugins: { legend: { labels: { color: string; }; }; }; scales: { x: { ticks: { color: string; }; grid: { color: string; drawBorder: boolean; }; }; y: { ticks: { color: string; }; grid: { color: string; drawBorder: boolean; }; }; }; };
	AmountSpentdata: { labels: any; datasets: { label: string; data: unknown; fill: boolean; borderColor: string; tension: number; }[]; };
	AmountBalancedata: { labels: any; datasets: { label: string; data: unknown; fill: boolean; borderColor: string; tension: number; }[]; };
	AmountBalanceOptions: { maintainAspectRatio: boolean; aspectRatio: number; plugins: { legend: { labels: { color: string; }; }; }; scales: { x: { ticks: { color: string; }; grid: { color: string; drawBorder: boolean; }; }; y: { ticks: { color: string; }; grid: { color: string; drawBorder: boolean; }; }; }; };
	departments: any;
	isShowAmount: boolean;
	getAmountSpentList: any;
	constructor(private DashboardService: DashboardService,
		// private spinner: NgxSpinnerService,
		private spinner: NgxSpinnerService,
		private SigninService: SigninService) {
	}
	ngOnInit(): void {
		this.getFunds();
		this.getDepartments();
		this.getLands()
		this.getDepartmentslist()
		this.getProjects()
		this.getProjectValue()
		this.getAmountReceive()
		this.getAmountSpent()
		this.getAmountBalance()
	}
	getFunds() {
		this.spinner.show()
		this.DashboardService.getFundReport().subscribe(res => {
			this.fundDetails = res.body.data
			this.spinner.hide()
		}, (err) => {
			this.spinner.hide()
		})
	}
	getDepartmentslist() {
		this.spinner.show()
		this.DashboardService.getDepartments().subscribe(res => {
			this.departments = res.body.data
			//console.log(this.departments ,'this.departments');
			this.spinner.hide()
		}, (err) => {
			this.spinner.hide()
		})
	}
	getAmountSpent() {
		//this.spinner.show()
		this.DashboardService.getSpentAmout().subscribe(res => {
			let AmountSpent= res.body.data
			//console.log(AmountSpent,'AmountReceive');
			const documentStyle = getComputedStyle(document.documentElement);
			const textColor = documentStyle.getPropertyValue('--text-color');
			const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
			const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
			this.AmountSpentdata = {
				labels: AmountSpent.label,
				datasets: [
					{
						label: Object.keys(AmountSpent.dataset)[0],
						data: Object.values(AmountSpent.dataset)[0],
						fill: false,
						borderColor: documentStyle.getPropertyValue('--blue-500'),
						tension: 0
					},
					{
						label: Object.keys(AmountSpent.dataset)[1],
						data: Object.values(AmountSpent.dataset)[1],
						fill: false,
						borderColor: documentStyle.getPropertyValue('--pink-500'),
						tension: 0
					}
				]
			};
			this.AmountSpentOptions = {
				maintainAspectRatio: false,
				aspectRatio: 0.6,
				plugins: {
					legend: {
						labels: {
							color: textColor
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: textColorSecondary
						},
						grid: {
							color: surfaceBorder,
							drawBorder: false
						}
					},
					y: {
						ticks: {
							color: textColorSecondary
						},
						grid: {
							color: surfaceBorder,
							drawBorder: false
						}
					}
				}
			}
			//this.spinner.hide()

		}, (err) => {
			//this.spinner.hide()

		})
	}
	getTotalAmount(){
		this.isShowAmount=true
		this.spinner.show()
		this.DashboardService.getSpentAmount().subscribe(res => {
			this.getAmountSpentList = res.body.data
			console.log(this.getAmountSpentList,'this.getAmountSpentList');
			this.spinner.hide()
		}, (err) => {
			this.spinner.hide()
		})
	}
	getAmountBalance() {
		//this.spinner.show()
		this.DashboardService.getAmountBalances().subscribe(res => {
			let AmountBalance= res.body.data
			const documentStyle = getComputedStyle(document.documentElement);
			const textColor = documentStyle.getPropertyValue('--text-color');
			const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
			const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
			this.AmountBalancedata = {
				labels: AmountBalance.label,
				datasets: [
					{
						label: Object.keys(AmountBalance.dataset)[0],
						data: Object.values(AmountBalance.dataset)[0],
						fill: false,
						borderColor: documentStyle.getPropertyValue('--blue-500'),
						tension: 0
					},
					{
						label: Object.keys(AmountBalance.dataset)[1],
						data: Object.values(AmountBalance.dataset)[1],
						fill: false,
						borderColor: documentStyle.getPropertyValue('--pink-500'),
						tension: 0
					},
					{
						label: Object.keys(AmountBalance.dataset)[2],
						data: Object.values(AmountBalance.dataset)[2],
						fill: false,
						borderColor: documentStyle.getPropertyValue('--green-500'),
						tension: 0
					}
				]
			};
			this.AmountBalanceOptions = {
				maintainAspectRatio: false,
				aspectRatio: 0.6,
				plugins: {
					legend: {
						labels: {
							color: textColor
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: textColorSecondary
						},
						grid: {
							color: surfaceBorder,
							drawBorder: false
						}
					},
					y: {
						ticks: {
							color: textColorSecondary
						},
						grid: {
							color: surfaceBorder,
							drawBorder: false
						}
					}
				}
			}
		}, (err) => {
		})
	}
	getAmountReceive() {
		//this.spinner.show()
		this.DashboardService.getAmountReceive().subscribe(res => {
			let AmountReceive= res.body.data
			//console.log(AmountReceive,'AmountReceive');
			const documentStyle = getComputedStyle(document.documentElement);
			const textColor = documentStyle.getPropertyValue('--text-color');
			const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
			const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
			this.AmountReceivedata = {
				labels: AmountReceive.label,
				datasets: [
					{
						label: Object.keys(AmountReceive.dataset)[0],
						data: Object.values(AmountReceive.dataset)[0],
						fill: false,
						borderColor: documentStyle.getPropertyValue('--blue-500'),
						tension: 0
					},
					{
						label: Object.keys(AmountReceive.dataset)[1],
						data: Object.values(AmountReceive.dataset)[1],
						fill: false,
						borderColor: documentStyle.getPropertyValue('--pink-500'),
						tension: 0
					}
				]
			};
			this.AmountReceiveOptions = {
				maintainAspectRatio: false,
				aspectRatio: 0.6,
				plugins: {
					legend: {
						labels: {
							color: textColor
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: textColorSecondary
						},
						grid: {
							color: surfaceBorder,
							drawBorder: false
						}
					},
					y: {
						ticks: {
							color: textColorSecondary
						},
						grid: {
							color: surfaceBorder,
							drawBorder: false
						}
					}
				}
			}
		}, (err) => {
			//this.spinner.hide()
		})
	}
	getProjectValue() {
		//this.spinner.show()
		let projectValue
		this.DashboardService.getProjectValue().subscribe(res => {
			projectValue = res.body.data
			//console.log(projectValue, 'projectValue');
			const documentStyle = getComputedStyle(document.documentElement);
			const textColor = documentStyle.getPropertyValue('--text-color');
			const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
			const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

			this.dataProjectValue = {
				labels: projectValue.label,
				datasets: [
					{
						label: Object.keys(projectValue.dataset)[0],
						backgroundColor: documentStyle.getPropertyValue('--yellow-500'),
						borderColor: documentStyle.getPropertyValue('--yellow-500'),
						data: Object.values(projectValue.dataset)[0]
					},
				]
			};
			this.optionsProjectValue = {
				maintainAspectRatio: false,
				aspectRatio: 0.8,
				plugins: {
					legend: {
						labels: {
							color: textColor
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: textColorSecondary,
							font: {
								weight: 500
							}
						},
						grid: {
							color: surfaceBorder,
							drawBorder: false
						}
					},
					y: {
						ticks: {
							color: textColorSecondary
						},
						grid: {
							color: surfaceBorder,
							drawBorder: false
						}
					}

				}
			};
			//this.spinner.hide()

		}, (err) => {
			//this.spinner.hide()
		})
	}

	getProjects() {
		//this.spinner.show()
		let projectResponce
		this.DashboardService.getProjectsss().subscribe(res => {
			projectResponce = res.body.data
			const documentStyle = getComputedStyle(document.documentElement);
			const textColor = documentStyle.getPropertyValue('--text-color');
			const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
			const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

			this.dataProject = {
				labels: projectResponce.months,
				datasets: [
					{
						label: 'Project',
						backgroundColor: documentStyle.getPropertyValue('--green-500'),
						borderColor: documentStyle.getPropertyValue('--green-500'),
						data: projectResponce.projects
					},
				]
			};

			this.optionsProject = {
				maintainAspectRatio: false,
				aspectRatio: 0.8,
				plugins: {
					legend: {
						labels: {
							color: textColor
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: textColorSecondary,
							font: {
								weight: 500
							}
						},
						grid: {
							color: surfaceBorder,
							drawBorder: false
						}
					},
					y: {
						ticks: {
							color: textColorSecondary
						},
						grid: {
							color: surfaceBorder,
							drawBorder: false
						}
					}

				}
			};
			//this.spinner.hide()

		}, (err) => {
			//this.spinner.hide()
		})
	}

	getLands() {
		this.sampleLands = []
		//this.spinner.show()
		this.DashboardService.getExtendLands().subscribe(res => {
			this.getLand = res.body.data
			const documentStyle = getComputedStyle(document.documentElement);
			const textColor = documentStyle.getPropertyValue('--text-color');
			const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
			const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
			this.dataSideBar = {
				labels: this.getLand.label,
				datasets: [
					{
						label: Object.keys(this.getLand.dataset)[0],
						backgroundColor: documentStyle.getPropertyValue('--blue-500'),
						borderColor: documentStyle.getPropertyValue('--blue-500'),
						data: Object.values(this.getLand.dataset)[0]
					},
					{
						label: Object.keys(this.getLand.dataset)[1],
						backgroundColor: documentStyle.getPropertyValue('--pink-500'),
						borderColor: documentStyle.getPropertyValue('--pink-500'),
						data: Object.values(this.getLand.dataset)[1]
					}
				]
			};
			this.optionsSideBar = {
				maintainAspectRatio: false,
				aspectRatio: 0.8,
				onClick: (e) => {
					//console.log(e, 'e');
				},
				plugins: {
					legend: {
						labels: {
							color: textColor
						},
						position: 'bottom'
					}
				},
				scales: {
					x: {
						ticks: {
							color: textColorSecondary,
							font: {
								weight: 500
							}
						},
						grid: {
							color: surfaceBorder,
							drawBorder: false
						}
					},
					y: {
						ticks: {
							color: textColorSecondary
						},
						grid: {
							color: surfaceBorder,
							drawBorder: false
						}
					}
				}
			};
			//this.spinner.hide()

		}, (err) => {
			//this.spinner.hide()
		})
	}

	getDepartments() {
		this.sampleDepartments = []
		//this.spinner.show()
		this.DashboardService.getRequestingDepartmet().subscribe(res => {
			this.requestingDepartments = res.body.data
			//this.spinner.hide()
			const documentStyle = getComputedStyle(document.documentElement);
			const textColor = documentStyle.getPropertyValue('--text-color');
			const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
			const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
			for (let key in this.requestingDepartments.dataset) {
				let obj = {
					label: key,
					backgroundColor: this.getRandomColor(),
					data: this.requestingDepartments.dataset[key]
				}
				this.sampleDepartments.push(obj)
			}
			this.dataBar = {
				labels: this.requestingDepartments.label,
				datasets: [
					{
						label: Object.keys(this.requestingDepartments.dataset)[0],
						backgroundColor: documentStyle.getPropertyValue('--blue-500'),
						borderColor: documentStyle.getPropertyValue('--blue-500'),
						data: Object.values(this.requestingDepartments.dataset)[0]
					},
					{
						label: Object.keys(this.requestingDepartments.dataset)[1],
						backgroundColor: documentStyle.getPropertyValue('--green-500'),
						borderColor: documentStyle.getPropertyValue('--green-500'),
						data: Object.values(this.requestingDepartments.dataset)[1]
					},
					{
						label: Object.keys(this.requestingDepartments.dataset)[2],
						backgroundColor: documentStyle.getPropertyValue('--yellow-500'),
						borderColor: documentStyle.getPropertyValue('--yellow-500'),
						data: Object.values(this.requestingDepartments.dataset)[2]
					}
				]
			};
			this.optionsBar = {
				indexAxis: 'y',
				maintainAspectRatio: false,
				aspectRatio: 0.8,
				plugins: {
					legend: {
						labels: {
							color: textColor
						},
						position: 'bottom',
						align: 'center'
					}
				},
				scales: {
					x: {
						ticks: {
							color: textColorSecondary,
							font: {
								weight: 500
							}
						},
						grid: {
							color: surfaceBorder,
							drawBorder: false
						}
					},
					y: {
						ticks: {
							color: textColorSecondary
						},
						grid: {
							color: surfaceBorder,
							drawBorder: false
						}
					}
				}
			};
		}, (err) => {
			//this.spinner.hide()
		})
	}

	visitorsChartDrilldownHandler = (e: any) => {
		this.projectTemp = []
		if (this.projectList == undefined) {
			//this.spinner.show();
			this.DashboardService.getProjectsByDistrict(e.dataPoint.id).subscribe(res => {
				this.projectList = res.body.data;
				//this.spinner.hide();

				this.projectList.label.forEach((element, i) => {
					let obj = {
						y: this.projectList.count[i],
						name: element.name,
						id: element.id,
					}
					this.projectTemp.push(obj)
				});
				this.options['district'][0].dataPoints = this.projectTemp
				this.chart.options = this.visitorsDrilldownedChartOptions;
				this.chart.options.data = this.options['district'];
				this.chart.options.title = { text: e.dataPoint.name }
				this.chart.render();
				this.isButtonVisible = true;
			})
		}
	}
	visitorsDrilldownedChartOptions = {
		animationEnabled: true,
		theme: "light2",
		axisY: {
			gridThickness: 0,
			lineThickness: 1
		},
		data: []
	};
	newVSReturningVisitorsOptions = {
		animationEnabled: true,
		theme: "light2",
		data: []
	};

	getRandomColor() {
		var letters = '0123456789ABCDEF';
		var color = '#';
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	options = {
		"New vs Returning Visitors": [{
			type: "pie",
			name: "New vs Returning Visitors",
			startAngle: 90,
			cursor: "pointer",
			explodeOnClick: false,
			showInLegend: true,
			legendMarkerType: "square",
			click: this.visitorsChartDrilldownHandler,
			indexLabelPlacement: "inside",
			indexLabelFontColor: "white",
			dataPoints: ''
		}],
		"district": [{
			name: "New Visitors",
			type: "pie",
			showInLegend: true,
			legendMarkerType: "square",
			dataPoints: ''
		}],
	};

	handleClick(event: Event) {
		this.projectList = undefined
		this.chart.options = this.newVSReturningVisitorsOptions;
		this.chart.options.data = this.options["New vs Returning Visitors"];
		this.chart.render();
		this.isButtonVisible = false;
	}

	getChartInstance(chart: object) {

		this.SigninService.getuserDto().subscribe(
			(res) => {
				if (res.body.role.id == 5 || res.body.role.id == 4) {
					this.districtName = res.body.levelMasterId.name

					this.DashboardService.getProjectsByDistrict(res.body.levelMasterId.id).subscribe(res => {
						this.projectList = res.body.data
						this.projectList.label.forEach((element, i) => {
							let obj = {
								y: this.projectList.count[i],
								name: element.name,
								id: element.id,
							}
							this.projectTemp.push(obj)
						});
						this.chart = chart;
						this.options['district'][0].dataPoints = this.projectTemp
						this.chart.options = this.visitorsDrilldownedChartOptions;
						this.chart.options.data = this.options['district'];
						this.chart.options.title = { text: this.districtName }
						this.chart.render();
						// this.isButtonVisible = true;
					})
				} else {
					this.tempDistrictArr = []
					this.DashboardService.getDistricts().subscribe(res => {
						this.districts = res.body.data;
						this.districts.label.forEach((element, i) => {
							let obj = {
								y: this.districts.count[i],
								name: element.name,
								id: element.id,
							}
							this.tempDistrictArr.push(obj)
						});
						this.options['New vs Returning Visitors'][0].dataPoints = this.tempDistrictArr
						this.chart = chart;
						this.chart.options = this.newVSReturningVisitorsOptions;
						this.chart.options.data = this.options["New vs Returning Visitors"];
						this.chart.render();

					})

				}

			})

	}

}