<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Day Wise Payment Status Report</h2>
</div>
<div class="container-fluid bg">
    <div class="tableDesign">
        <div class="row px-4 bgFilter align-items-center">
            <div class="row align-items-center mt-2">
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req">Project
                        Name</label>
                    <ng-select id="department" bindLabel="projectName" bindValue='id'
                        [(ngModel)]="stage1Filter.projectName" [items]="projectList" appearance="outline"
                        class="form-control" appNgSelectInputRestriction>
                    </ng-select>
                </div>
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req">
                        Name</label>
                    <input type="text" class="form-control" name="name" [(ngModel)]="stage1Filter.name"
                        autocomplete="off" maxlength="60" id="name" appSerachInputRestriction />
                </div>
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req">Mobile Number</label>
                    <input type="text" class="form-control" name="name" autocomplete="off" maxlength="60"
                        [(ngModel)]="stage1Filter.mobile" (keypress)="keyPressNumberForMobile($event)"
                        style="text-transform:uppercase" id="name" appSerachInputRestriction />
                </div>
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req">
                        Aadhar Number</label>
                    <input type="text" class="form-control" [(ngModel)]="stage1Filter.aadhaar" name="name"
                        autocomplete="off" maxlength="60" (keypress)="keyPressNumberForAadhaar($event)"
                        style="text-transform:uppercase" id="name" appSerachInputRestriction />
                </div>
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req">
                        Payment Status</label>
                    <ng-select id="department" [(ngModel)]="stage1Filter.status" [items]="paymentStatus"
                        appearance="outline" class="form-control" appNgSelectInputRestriction>
                    </ng-select>
                </div>
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req">
                        From Date</label>
                    <p-calendar dateFormat="dd/mm/yy" [readonlyInput]="true" [maxDate]="minDate" [(ngModel)]="fromDate"
                        appendTo="body"></p-calendar>&nbsp;
                </div>
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req">
                        To Date</label>
                    <p-calendar [readonlyInput]="true" [(ngModel)]="toDate" [maxDate]="minDate" [readonlyInput]="true"
                        [minDate]="fromDate" dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                </div>
                <div class="col-sm-4 col-md-3">
                    <div class="flex align-items-center mt-3 ">
                        <p-button label="Submit" class="submit" (click)="loadPage()"></p-button> &nbsp;
                        <p-button label="Clear" class="submit1" (click)="clear()"></p-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Day Wise Payment Status Report
                    </h5>
                </ng-template>
                <ng-template pTemplate="right">
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row mt-3">
        <div #customerTable id="entities" class="col-md-12 px-1">
            <div class="tableScroll">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">S.No
                            </th>
                            <th scope="col">Project Name <span (click)="loadPage(page,'projectName')"><i
                                        class="fa fa-sort"></i> </span>
                            </th>
                            <th scope="col">Beneficiary Name <span
                                    (click)="loadPage(page,'benificiaryLandOwnerName')"><i class="fa fa-sort"></i>
                                </span>
                            </th>
                            <th scope="col">District <span (click)="loadPage(page,'district')"><i
                                        class="fa fa-sort"></i>
                                </span></th>
                            <th scope="col">Survey
                                Number <span (click)="loadPage(page,'surveyNumber')"><i class="fa fa-sort"></i> </span>
                            </th>
                            <th scope="col">Subdivision
                                Number <span (click)="loadPage(page,'subDivisionNumber')"><i class="fa fa-sort"></i>
                                </span>
                            </th>
                            <th scope="col">Mobile
                                Number <span (click)="loadPage(page,'mobileNumber')"><i class="fa fa-sort"></i> </span>
                            </th>
                            <th scope="col">Bank Name <span (click)="loadPage(page,'nameOfBank')"><i
                                        class="fa fa-sort"></i>
                                </span>
                            </th>
                            <th scope="col">Account Number <span (click)="loadPage(page,'accountNumber')"><i
                                        class="fa fa-sort"></i> </span>
                            </th>
                            <th scope="col">Aadhaar Number <span (click)="loadPage(page,'aadharNumber')"><i
                                        class="fa fa-sort"></i> </span>
                            </th>
                            <th scope="col">Voucher Number <span (click)="loadPage(page,'voucherNumber')"><i
                                        class="fa fa-sort"></i> </span>
                            </th>
                            <th scope="col">Amount <span (click)="loadPage(page,'totalAmount')"><i
                                        class="fa fa-sort"></i>
                                </span>
                            </th>
                            <th scope="col">Extend Acquired <span (click)="loadPage(page,'extentOwned')"><i
                                        class="fa fa-sort"></i>
                                </span>
                            </th>
                            <th scope="col">Payment Date
                            </th>
                            <th scope="col">Payment Status
                            </th>
                            <th scope="col">Utr No
                            </th>
                            <th scope="col">Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of paymentList;let i =index">
                            <td>{{generateIndex(i)}}</td>
                            <td>{{data.projectName}}</td>
                            <td>{{data.benificiaryLandOwnerName}}</td>
                            <td>{{data.district}}</td>
                            <td>{{data.surveyNumber}}</td>
                            <td>{{data.subDivisionNumber}}</td>
                            <td>{{data.mobileNumber}}</td>
                            <td>{{data.nameOfBank}}</td>
                            <td>{{data.accountNumber}}</td>
                            <td>{{data.aadharNumber}}</td>
                            <td>{{data.voucherNumber}}</td>
                            <td>{{data.totalAmount |indianRupee }}</td>
                            <td>{{data.extentOwned }}</td>
                            <td> {{data.updatedOn | date}}</td>
                            <td> {{data.paymentStatus}} </td>
                            <td> {{data.utrNo}}</td>
                            <td class="download_link" (click)="downdloadPdf(data)">Download Pdf</td>
                        </tr>
                        <tr *ngIf="totalCount != 0"
                            style="background: rgb(202 206 208 / 50%); color: black;vertical-align: middle;">
                            <td colspan="11">Total</td>
                            <td>{{totalAmount | indianRupee}}</td>
                            <td>{{extendOwned | number}}</td>
                            <td colspan="4"></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="text-center" *ngIf="totalCount == 0">
                <img src="../../../assets/img/notFound.png" alt="">
            </div>
            <p-paginator [rows]="itemsPerPage" (onPageChange)="onPageChange($event)" [totalRecords]="totalCount"
                [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="agencyDialog" [style]="{ width: '850px' }" [modal]="true" [closable]="true"
        styleClass="ib-dialog" appendTo="body">
        <p-header [ngClass]="fontService.headingClass">
            District User
        </p-header>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>