import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class SecureTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient, private baseUrl: string) {}
  protected getlangJsonUrl = environment.SERVER_API_URL + ('api/getHeaderData/en');
  getenjson(): any {
    return this.http.get(this.getlangJsonUrl, { responseType: 'text' });
  }
  getTranslation(lang: string): Observable<any> {
    const url = this.getlangJsonUrl;
    return this.http.get(url); // Auth token will be added via the interceptor
  }
}