import { NotificationService } from 'src/app/alert/notification.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SigninService } from './signin.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import * as forge from 'node-forge';
import * as CryptoJS from 'crypto-js';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Captcha } from './login.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { NavbarService } from 'src/app/layout/navbar/navbar.service';
import {
  UserContext,
  UserRoleService,
} from 'src/app/shared/service/user-role.service';
import { Subscription } from 'rxjs';

import { filter } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { User, authModel } from './signin.module';
import { EncryptService } from 'src/app/shared/encrypt.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ib-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit, AfterViewInit {
  @ViewChild('username', { static: false })
  username!: ElementRef;

  showOtp = false;
  inValidCaptcha = false;
  inValidUser: boolean = false;
  publicKey: string;
  loginForm = this.fb.group({
    username: [null, [Validators.required]],
    password: [null, [Validators.required]],
    captcha: [null, [Validators.required]],
    rememberMe: [false],
  });

  otpForm = this.fb.group({
    otp: [null, [Validators.required]],
  });
  image: any;
  captchaText: any;
  otpTimer: number;
  timer: any;
  enableResendButton: boolean =false;
  disableButton: boolean = false;
  otpError: string;
  passwordReset: string;
  profileStatus: string;
  uname: string;
  pwd: string;
  hidden: boolean = true;
  captchaDetails: Captcha;
  captchaExpireError: string;
  reSendCount: number = 1;
  otpId: any;
  spinners: boolean;
  passwordError: string;
  userNameError: string;
  lockedError: string;
  roleCode: any;
  disableOtp: boolean;
  resentRedirect: boolean = false;

  constructor(
    private loginService: SigninService,
    private router: Router,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private navbarService: NavbarService,
    protected notificationService: NotificationService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private userRoleService: UserRoleService,
    public spinner: NgxSpinnerService,
    private httpClient: HttpClient,
  private translateService: TranslateService,
  ) { }

  async ngOnInit() {
    // this.localStorageService.clear();
    // this.sessionStorageService.clear();
    const preStatusResult = await this.loginService.preStatus().toPromise();
    await this.loadCaptcha();
    // if already authenticated then navigate to home page
  }
  loadCaptcha(): Promise<any> {
    this.spinners = true;
    this.loginForm.controls['captcha'].setValue('');
    this.loginService.getCapthcaImage().subscribe(
      (res: any) => {
        this.spinners = false;
        //console.log(res)
        this.captchaDetails = JSON.parse(res);
        let objectURL = 'data:image/jpeg;base64,' + this.captchaDetails.captchaImage;
        this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        
      },
      (onError) => {
        this.spinners = false;
      }
    );

    return Promise.resolve('Captcha Loaded');  
  }

  startTimer() {
    this.otpTimer = 60;

    this.timer = setInterval(() => {
      this.otpTimer--;

      if (this.otpTimer === 0) {
        this.stopTimer();
        this.otpError = null;
      }
    }, 1000);
  }

  stopTimer() {
    if (this.reSendCount < 2) {
      this.enableResendButton = true;
      this.reSendCount = this.reSendCount + 1;
    }
    else {
      this.logout();
      this.showOtp = false;
      this.loginForm.reset();
      this.otpError = null;
      this.reSendCount = 1;
      this.otpForm.controls['otp'].setValue('');
    }
    clearInterval(this.timer);
  }

  ngAfterViewInit(): void {
    // this.username.nativeElement.focus();
  }
  navToForgotPassword() {
    if (this.loginForm.get('username').value) {
      this.router.navigate(['/ForgotPassword'], {
        queryParams: { username: btoa(this.loginForm.get('username')!.value) },
      });
    }
    else {
      this.router.navigate(['/ForgotPassword']);
    }
  }
  navToResetPassword() {
    if (this.loginForm.get('username').value) {
      this.router.navigate(['/ResetPassword'], {
        queryParams: { username: btoa(this.loginForm.get('username')!.value) },
      });
    }
    else {
      this.router.navigate(['/ResetPassword']);
    }
  }

  loginOtp(): void {
    this.otpError = null;
    this.disableOtp = false;
    if (!this.otpForm.get('otp')!.value) {
      this.otpError = 'Invalid Otp';
      return;
    }
    this.loginService.otpvalidate(this.encryptDatatest(this.otpForm.get('otp')!.value),
      this.otpId).subscribe(
        (res) => {

          //console.log('fcffffffffffffffffffffffffffffffff', res);
          //console.log('fcffffffffffffffffffffffffffffffff', res);

          if (res === "Correct") {
            
            let payload = new User();
            payload.otp = this.encryptDatatest(this.otpForm.get('otp')!.value);
            payload.userName = this.encryptDatatest(this.loginForm.get('username')!.value);
            payload.roleCode = this.encryptDatatest(this.roleCode);
            this.localStorageService.clear('X_FORM_CODE')
            this.localStorageService.store(
              'X_FORM_CODE',
              this.encryptDatatest(JSON.stringify(payload))
            );
            // if (this.passwordReset == "Yes") {
            //   this.navToResetPassword();
            // }

            this.loginService.checkOtpValidate(this.otpId).subscribe(

              (res) => {
                let splitArray: string[];
                splitArray = res.split("\\");

                if (splitArray[0] === payload.otp && splitArray[1] === payload.roleCode) {
                  this.getUserrole();
                  this.getenJson();
                  this.router.navigateByUrl('home');
                } else {
                  this.navToLogin();
                }
              }),
              (error) => {
                this.navToLogin();
              }

           
          }



        },
        (onError) => {
          //this.spinner.hide();
          if (onError.status === 400) {
            this.otpError = onError.error;
            if (this.resentRedirect && onError.error === 'Maximum Attempt Reached') {
              this.otpError = null;
              this.navToLogin();
            } else if (onError.error === 'Maximum Attempt Reached') {
              this.disableOtp = true;
              this.stopTimer();
              this.enableResendButton = true;
            }
          }
        }
      );
  }
  login(): void {

    this.inValidCaptcha = false;
    this.inValidUser = false;
    this.captchaExpireError = null;
    this.passwordError = null;

    this.userNameError = null;

    this.passwordError = null;

    this.lockedError = null;

    if (
      !this.loginForm.get('username')!.value ||
      !this.loginForm.get('password')!.value
    ) {
      this.inValidUser = true;
      return;
    }
    if (!this.loginForm.get('captcha')!.value) {
      this.inValidCaptcha = true;
      return;
    }
    if (!this.passwordPolicyValidator(this.loginForm.get('password')!.value)) {
      // Display an error message or handle the password policy violation
      this.passwordError =
        'Password must be a minimum of 8 characters including Number, Upper, Lower and one special character';
      return;
    }
    this.spinner.show();
    let payload = new authModel();
    payload.username = this.loginForm.get('username')!.value;
    payload.password = this.loginForm.get('password')!.value;
    payload.rememberMe = true;
    payload.captchaText = this.loginForm.get('captcha')!.value;
    payload.id = this.captchaDetails.captchaId;
    this.loginService
      .login({ requestPayload: this.encryptDatatest(JSON.stringify(payload)) })
      .subscribe(
        () => {
          // this.sharedService.getMasterService();
          let authToken=this.localStorageService.retrieve('authenticationToken') ?? this.sessionStorageService.retrieve('authenticationToken');
          if(authToken){
          this.getPassWordData()}
         //this.getUserrole();
          this.spinner.hide();
        },
        (onError) => {
          //console.log('error', onError);
          this.spinner.hide()
          if (onError.status == 400 || onError.status == 500 || onError.status == 409 || onError.status == 401 || onError.status == 404) {
            this.userNameError = onError.error.message;
          }
          else if (onError.status == 404) {
            this.inValidUser = true;
          }

          else {
            this.inValidUser = true;
          
          }
          this.loadCaptcha();
        }
      );
  }

  passwordPolicyValidator(password: string): boolean {
    const minLength = 8;
const maxLength =15;
    const hasUpperCase = /[A-Z]/.test(password);    
    const hasLowerCase = /[a-z]/.test(password);    
    const hasNumeric = /[0-9]/.test(password);      
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password); 
    
 
    return (password.length >= minLength && hasUpperCase && hasLowerCase && hasNumeric && hasSpecialChar) && (password.length<maxLength);
  }
  getUserrole() {
 
    this.loginService.getuserDto().subscribe(
      (res) => {
        this.userRoleService.setUserRole(res.body.role.code, res.body.role.name);
        this.roleCode = res.body.role.code;
        this.passwordReset = res.body.resetPassword;
        this.profileStatus = res.body.profileStatus;
        // let payload = new User();
        // payload.userName = this.encryptDatatest(this.loginForm.get('username')!.value);
        // payload.roleCode = this.encryptDatatest(this.roleCode)
        // this.localStorageService.store(
        //   'X_FORM_CODE',
        //   this.encryptDatatest(JSON.stringify(payload))
        // );
        // if (this.passwordReset !== 'Yes') {
        //   this.getUserOtp();
        //   this.startTimer();
        // }
        // else {
        //   this.showOtp = false;
        //   this.navToResetPassword();
        // }
      },
      (onError) => { }
    );
  }
  getPassWordData(){
    this.loginService.getPasswordDto().subscribe(
      (res) => {
        //console.log('324343243243244',res);
        this.passwordReset = res.body.resetPassword;
        this.roleCode = res.body.role.code;
        localStorage.setItem('roleCodeValue',this.encryptDatatest(this.roleCode));
        let payload = new User();
        payload.userName = this.encryptDatatest(this.loginForm.get('username')!.value);
        payload.roleCode = this.encryptDatatest(this.roleCode)
        this.localStorageService.store(
          'X_FORM_CODE',
          this.encryptDatatest(JSON.stringify(payload))
        );
        if (this.passwordReset !== 'Yes') {
          this.getUserOtp();
          this.startTimer();
        }
        else {
          this.showOtp = false;
          this.navToResetPassword();
        }
      })

  }
  getenJson(){

// this.loginService.getenjson().subscribe(res=>{
//   console.log(res)
// })
    // this.httpClient
    // .get('/assets/i18n/en.json')
    // .subscribe((res: any) => {
    
    //   this.translateService.use('en');
    // })
    this.translateService.setDefaultLang('en');
  }
  getUserOtp(resend?) {
    this.loginService.getOtp().subscribe(
      (res) => {
        this.otpId = res;
        if (resend) {
          this.disableOtp = false
          this.otpError = null;
          this.enableResendButton = false;
          this.startTimer();
          this.resentRedirect = true;
        }
        else {
          this.showOtp = true;
        }
      },
      (onError) => { }
    );
  }
  navToLogin() {
    this.logout();
    this.showOtp = false;
    this.loginForm.reset();
  }
  logout(): void {
    this.loginService.stopRefreshTokenTimer();
    this.navbarService.logout().subscribe(
      (res: HttpResponse<any>) => {
        this.localStorageService.clear();
        this.sessionStorageService.clear();
      },
      (err) => { }
    );
  }

  encryptDatatest(data: string): string {
    const captchaLength = 16; // Specify the desired captcha length
    const saltCharacters =
      'abranNumcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ234567890';
    let captchaStr = '';

    while (captchaStr.length < captchaLength) {
      const index = Math.floor(Math.random() * saltCharacters.length);
      captchaStr += saltCharacters.charAt(index);
    }
    const captcha = captchaStr;
    //console.log(captcha);
    //console.log(captcha.toString());
    const key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
    const iv = CryptoJS.enc.Utf8.parse(captcha);
    const paddedData = this.zeroPadData(data).toString();
    const encryptedData = CryptoJS.AES.encrypt(paddedData, key, {
      iv: iv,
    }).toString();
    const encryptedTextWithIV = captcha.toString() + encryptedData.toString();

    const base64CipherText1 = encryptedData.toString(CryptoJS.enc.Base64);

    //console.log('base64CipherText1' + base64CipherText1);

    const base64CipherText = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(encryptedTextWithIV)
    );
    //console.log('base64CipherText', base64CipherText);
    return base64CipherText;
  }

  zeroPadData(data: string): string {
    const blockSize = 16;
    const paddingLength = blockSize - (data.length % blockSize);
    const paddedData = data + '\x00'.repeat(paddingLength);
    return paddedData;
  }

  
}