import { Component, OnInit } from '@angular/core';
import { PaymentService } from './payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { UserService } from '../checker-project/checker.service';
import { filterQuery } from 'src/app/shared/common.model';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConfirmationService, MessageService, ConfirmEventType } from 'primeng/api';
import { FontService } from 'src/app/shared/font-service/font.service';
import { RDService } from '../biometric-test/biometric-test-service';
import { ProjectService } from 'src/app/project/project.service';
import { NotificationService } from 'src/app/alert/notification.service';
interface HttpParams {
  survayNo: string;
  mobileNo: Number;
  name: string;
}
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  beneficiaryData: any;
  scan=false
  showTable: boolean = false;
  fundFilter
  roleId: boolean;
  projectList: any;
  index: any;
  registrationStatusField: string = 'please capture fingure print';
  figureData:any;



  pageCount: number = 0;
  itemsPerPage: any = 100;
  visible = false
  checked: boolean = false;
  checkData:boolean[] = [false];
  pageLength: any;
  page: number;
  filterProps: any;
  paymentList: any;
  totalCount: any
  selectedData: any;
  selecTProjectId: any;
  enableGenerateVoucher: boolean = true;
  temp: any = [];
  availableAmount: any = {};
  payloadArr: any[] = [];
  sortDirection: any = 'asc';
  signaturedialog: boolean;
  sign: boolean;
  param1: any;
  enableSignButton: boolean;
  param2: any;
  param3: any;
  VoucherNo: string;
  enableSignatureButton: boolean = false;
  paymentSucessText: string;
  paymentSucessDialog: boolean;
  userDto: Promise<any>;
  disableBioMetric: boolean = false;
  enableAllBiometric: boolean =false;
  enableEsign: boolean =false;
  perform: boolean =false;
  constructor(
    private PaymentService: PaymentService,
    protected activatedRoute: ActivatedRoute,
    protected notificationService: NotificationService,
    private spinner: NgxSpinnerService,
    private UserService: UserService,
    private router: Router,
    private projectService :ProjectService,
    private rdService:RDService,
    private loginService: SigninService,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    public fontService: FontService
  ) { 
    //  this.loginService.preStatus().toPromise();
  }
  hamHeader = 'Make Payment'
  subHeader = 'Payment / Make Payment'
  searchParams: any = {};
  async ngOnInit() {
    await this.loginService.preStatus().toPromise();
    // this.signaturedialog = true;
    this.rdService.updatebiometric(null);

    if (this.activatedRoute.snapshot.queryParamMap.get('paymentSuccess')){
      this.paymentSucessText = 'Transaction Completed with Reference No : ' + this.activatedRoute.snapshot?.queryParamMap?.get('paymentSuccess');
        this.paymentSucessDialog = true;
      // this.notificationService.alertSuccess(this.activatedRoute.snapshot?.queryParamMap?.get('paymentSuccess'),'');
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('paymenterror')){
      this.paymentSucessText =  this.activatedRoute.snapshot?.queryParamMap?.get('paymenterror');
        this.paymentSucessDialog = true;
      // this.notificationService.alertSuccess(this.activatedRoute.snapshot?.queryParamMap?.get('paymentSuccess'),'');
    }
    this.getUserrole();
    this.getProjects()
  }

  stage1Filter = {
    projectName: '',
    surveyNo: '',
    subdivision: '',
    villageName: '',
    talukName: ''
  }
  products = [
    {
      id: '1000',
      code: 'Test User 1',
      name: '9988774455',
      description: 'Product Description',
      image: 'bamboo-watch.jpg',
      price: 76566565475432,
      category: 'SBI',
      quantity: 765464356742,
      inventoryStatus: 'INSTOCK',
      rating: 100000,
    },
    {
      id: '1000',
      code: 'Test User 2',
      name: '9988774455',
      description: 'Product Description',
      image: 'bamboo-watch.jpg',
      price: 76566565475432,
      category: 'IOB',
      quantity: 765464356741,
      inventoryStatus: 'INSTOCK',
      rating: 100000,
    },
  ];


  selectProject(event) {
    this.selecTProjectId = event.id
    this.userDto = this.getUserroleData();
    this.userDto.then((value:any) => {
      // if(value.userName === 'DROCHN' ){
      this.enableSignatureButton = true;
    //  this.disableBioMetric = true;
    // }
  }).catch(error => {
      console.error('Error handling userDto:', error);
  });
    this.loadPage(this.page, event.id)
  }

  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }
  onPageChange(event: any) {
    //console.log(event);
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    this.page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
  }


  loadPage(page?: number, projectID?: any, params?): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query
    if (params) {
      query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        project: projectID,
        sortBy: params,
        sortDirection: this.sortDirection
      };
      if (this.sortDirection == 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    } else {
      query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        project: projectID
      };
    }

    this.UserService.getListOfBeneficiary({ ...query }).subscribe(
      (res: HttpResponse<any[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad);
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  redirectsuccess() {
    this.router.navigate(['/payment']);
  }

  generateVoucher(e, data,index) {
    this.checkData = this.checkData.map((value, i) => i === index);
    this.temp = [];
    if (e.checked) {
      this.enableGenerateVoucher = false;
      if (this.temp.length != 0) {
        let isCheck = this.temp.every((currentValue) => currentValue.id != data.id)
        if (isCheck) {
          this.temp.push(data)
        }
      } else {
        this.temp.push(data)
      }
    } else {
      let delIndex = this.temp.findIndex(element => element.id == data.id);
      this.temp.splice(delIndex, 1)
      //console.log(this.temp, ' this.temp');
      if (this.temp.length == 0) {
        this.enableGenerateVoucher = true;
      } else {
        this.enableGenerateVoucher = false;
      }
    }
  }

  updateVoucher(data:any){
    this.VoucherNo = data.voucherNumber;
    //console.log('000000000000000008888888888888888888888888888',this.VoucherNo);
  }

  confirm1(event: Event, lable) {

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure that you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.submit(lable)

      },
      reject: () => {

      }
    });
  }
  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,
  ): void {
    // this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.showTable = true

    this.page = page;
    this.paymentList = data.data;
    this.totalCount = data.totalCount;
  }

  submit(label) {
    let payload
    this.payloadArr = []

    if (label == 'approve') {
      this.temp.forEach(element => {
        payload =
        {
          "beneficiaryLandDetailId": element.benificiaryLandDetail.id,
          "beneficiaryLandOwnerId": element.id,
          "benificiaryLandOwnerName": element.benificiaryLandOwnerName,
          "aadharNumber": element.aadharNumber,
          "mobileNumber": element.mobileNumber,
          "nameOfAccountHolder": element.nameOfAccountHolder,
          "nameOfBank": element.nameOfBank,
          "accountNumber": element.accountNumber,
          "branchName": element.branchName,
          "ifscCode": element.ifscCode,
          "compensationAmount": element.compensationAmount,
          "surveyNumber": element.surveyNumber,
          "subDivisionNumber": element.subDivisionNumber,
          "extentOwned": element.extentOwned,
          "voucherNumber": element.voucherNumber,
          "isConfirmed": element.isConfirmed,
          "laoStatus": 'Approved by LAO'
        }
        this.payloadArr.push(payload)
      });
      this.recommended()
    } else if (label == 'revert') {
      this.temp.forEach(element => {
        payload = {
          "beneficiaryLandDetailId": element.benificiaryLandDetail.id,
          "beneficiaryLandOwnerId": element.id,
          "benificiaryLandOwnerName": element.benificiaryLandOwnerName,
          "aadharNumber": element.aadharNumber,
          "mobileNumber": element.mobileNumber,
          "nameOfAccountHolder": element.nameOfAccountHolder,
          "nameOfBank": element.nameOfBank,
          "accountNumber": element.accountNumber,
          "branchName": element.branchName,
          "ifscCode": element.ifscCode,
          "compensationAmount": element.compensationAmount,
          "surveyNumber": element.surveyNumber,
          "subDivisionNumber": element.subDivisionNumber,
          "extentOwned": element.extentOwned,
          "isConfirmed": element.isConfirmed,
          "laoStatus": 'Reverted by LAO'
        }
        this.payloadArr.push(payload)
      });
      this.PaymentService.save(this.payloadArr).subscribe(res => {
        this.visible = false
        this.enableGenerateVoucher = true
        this.checkData=[false]
        this.loadPage(this.page, this.selecTProjectId)
      })
    } else if (label == 'reject') {
      this.temp.forEach(element => {
        payload = {
          "beneficiaryLandDetailId": element.benificiaryLandDetail.id,
          "beneficiaryLandOwnerId": element.id,
          "benificiaryLandOwnerName": element.benificiaryLandOwnerName,
          "aadharNumber": element.aadharNumber,
          "mobileNumber": element.mobileNumber,
          "nameOfAccountHolder": element.nameOfAccountHolder,
          "nameOfBank": element.nameOfBank,
          "accountNumber": element.accountNumber,
          "branchName": element.branchName,
          "ifscCode": element.ifscCode,
          "compensationAmount": element.compensationAmount,
          "surveyNumber": element.surveyNumber,
          "subDivisionNumber": element.subDivisionNumber,
          "extentOwned": element.extentOwned,
          "isConfirmed": element.isConfirmed,
          "laoStatus": 'Rejected by LAO'
        }
        this.payloadArr.push(payload)
      })
      this.PaymentService.save(this.payloadArr).subscribe(res => {
        this.visible = false
        this.enableGenerateVoucher = true
        this.checkData=[false]

        this.loadPage(this.page, this.selecTProjectId)
      })
    } else if (label == 'rejected') {
      this.PaymentService.reject(this.VoucherNo).subscribe(
        (res) => {
          //console.log('fwefewfewfwefewfew', res);
          this.visible = false
      this.enableGenerateVoucher = true
      this.checkData=[false]

      this.loadPage(this.page, this.selecTProjectId)
          this.notificationService.alertSuccess(res.body,'');
        }
      ),(error) => {
        //console.log('fwefewfewfwefewfew', error);
        this.notificationService.alertSuccess(error.error,'');


      };
      
    }
  }
  submitPayment() {
    this.PaymentService.save(this.payloadArr).subscribe(res => {
      this.visible = false
      this.enableGenerateVoucher = true
      //console.log('9999999999999999999999999999999999999999' ,this.enableAllBiometric);
      this.enableAllBiometric = true;
this.enableEsign=true;
      this.temp = [];
      this.payloadArr = []
      this.loadPage(this.page, this.selecTProjectId)

    })
  }


  recommended() {
    this.PaymentService.recommend(this.payloadArr, this.selecTProjectId).subscribe(
      (res: any) => {
        this.visible = true
        console.log(res.body.data);
        console.log('dhbvdbvhjdbvhjds jv ds vnds vdsv',res.body.data.Beneficiary);
        this.availableAmount = res.body.data.Beneficiary
        this.checkData=[false]

      },
      (err) => {
        // this.notificationService.alertError('Error!', err.error.error);
        this.spinner.hide();
      }
    );

  }
  getProjects() {
    this.UserService.getProjects().subscribe(
      (res: any) => {
        this.projectList = res.body.data;
      },
      () => {
        // this.onError();
      }
    );
  }


  getUserrole() {
    this.loginService.getuserDto().subscribe(
      (res) => {
        if (res.body.role.id == 4 || res.body.role.id == 3) {
          this.roleId = true
        } else {
          this.roleId = false
        }
      },
      (onError) => { }
    );
  }

  getUserroleData():Promise<any>{
      return new Promise<any> (
        (resolve) => {
        this.loginService.getuserDto().subscribe(
          res => {
            //console.log('rferetretertretretretretre',res.body);
            resolve(res.body);
          },
          () => {resolve(null)}
        );
      })
  }
  generate() {
    this.visible = true
  }

  openNew(){
    this.signaturedialog = true;
    this.rdService.fingureprint.subscribe( res => {
      this.figureData = res ? res : this.registrationStatusField;
      if(this.figureData.startsWith('fingure registered successfully')){
        //console.log('dsadsada'+ this.enableSignatureButton);
        this.getfile();
        this.enableSignatureButton = true;
        this.perform = true;
        this.enableAllBiometric=false;
        this.enableEsign=true;
        this.signaturedialog = false;
        this.notificationService.alertSuccess(this.figureData,'');
      }
    });
  }
  
  hideDialog(){
    this.signaturedialog = false;
  }

  enabledSignature(){
    this.getfile();
    this.perform = true;
    this.enableSignatureButton = true;
    this.enableEsign=false;
  }

  getfile() {
    this.projectService
      .getEmudrarequest(this.VoucherNo)
      .subscribe(
        (res: HttpResponse<any>) => {
       
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          this.enableSignButton = true;
          // if(!this.voucherGenerationDto.vendorTransactionDto.id){
          //   this.router.navigate(['/PaymentVoucher'], {
          //     queryParams: {
          //       id: res.body.paramFour
          //     },
          //   });
          // }
          // add more parameters as needed
        },
        () => {}
      );
  }


}
